import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AppBarWithDrawer from "./components/AppBarWithDrawer";
import Login from "./pages/Auth/Login";
import AppRoutes from "./routes";

function App() {
  const [token, setToken] = useState(localStorage.getItem("accessToken"));

  useEffect(() => {
    const checkTokenExpiration = () => {
      const tokenExpiration = localStorage.getItem("tokenExpiration");
      const currentTime = new Date().getTime();

      if (tokenExpiration && currentTime > Number(tokenExpiration)) {
        console.log("Token expired, logging out...");
        handleLogout();
      } else {
        setToken(localStorage.getItem("accessToken"));
      }
    };

    checkTokenExpiration(); // Run once on page load

    // Set up an interval to check expiration every 5 minutes
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("user");
    setToken(null);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login setToken={setToken} />} />
        {token ? (
          <Route path="/" element={<AppBarWithDrawer setToken={setToken} />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="*" element={<AppRoutes />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
