import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const OfferTypeForm = () => {
  const [formData, setFormData] = useState({
    offer: "",
    typeId: "",
    typeName: "",
    colorCoupon: "",
    image: null,
    bonus: false,
    unit: "",
    couponValue: "",
    spd: false,
    active: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, image: e.target.files[0] }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "100vh",
      }}
    >
      <Card
        sx={{
          p: 0,
          borderRadius: 3,
          boxShadow: 5,
          backgroundColor: "#f9f9f9",
          width: "100%",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#5052ba" }}
          >
            Offer Type Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Offer</InputLabel>
                  <Select
                    name="offer"
                    value={formData.offer}
                    onChange={handleChange}
                  >
                    <MenuItem value="Offer 1">Offer 1</MenuItem>
                    <MenuItem value="Offer 2">Offer 2</MenuItem>
                    <MenuItem value="Offer 3">Offer 3</MenuItem>
                    <MenuItem value="Offer 4">Offer 4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Type ID"
                  name="typeId"
                  value={formData.typeId}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Type Name"
                  name="typeName"
                  value={formData.typeName}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Color Coupon"
                  name="colorCoupon"
                  value={formData.colorCoupon}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink>Upload Image</InputLabel>
                  <Box
                    sx={{
                      border: "1px dashed #1976d2",
                      borderRadius: 2,
                      p: 2,
                      textAlign: "center",
                      cursor: "pointer",
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50px",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                  >
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <Typography variant="body1" sx={{ color: "#1976d2" }}>
                      Choose Image
                    </Typography>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.bonus}
                      onChange={handleChange}
                      name="bonus"
                    />
                  }
                  label="Bonus"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Unit"
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Coupon Value"
                  name="couponValue"
                  value={formData.couponValue}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.spd}
                      onChange={handleChange}
                      name="spd"
                    />
                  }
                  label="SPD"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.active}
                      onChange={handleChange}
                      name="active"
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  sx={{ borderRadius: 3, mt: 2, background: "#5051ba" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default OfferTypeForm;
