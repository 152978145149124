import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import * as XLSX from "xlsx";
import { retry } from "@reduxjs/toolkit/query";
import { toWords } from "number-to-words";

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  fullscreen = false,
  maxWidth = "xs",
  MyBgColor,
  PDFData,
  ExcelData,
  ViewDATA,
  PDF_GeneraTor,
  Discounted_Amount,
  free_pkts_discount,
  company_scheme_disc,
}) => {
  const [orderData, setOrderData] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [SummaryInfo, setSummaryInfo] = useState([]);
  const [totalSum, setTotalSum] = useState(0); // State to hold the total sum

  useEffect(() => {
    if (ViewDATA && Array.isArray(ViewDATA.data)) {
      // Destructure the main parts of the response
      const items = ViewDATA.data.slice(0, -1); // All except the last one entries
      const additionalInfo = ViewDATA.data[ViewDATA.data.length - 1]; // Last entry for additional info
      const summaryInfo = ViewDATA.data[ViewDATA.data.length - 2]; // Second last entry for summary

      // Update states for rendering
      setOrderData(items); // Table rows
      setAdditionalInfo(additionalInfo); // Invoice and creation date
      setSummaryInfo(summaryInfo); // Summary data like stockistname
    } else {
      console.warn("ViewDATA is undefined or not in the expected format.");
    }
  }, [ViewDATA]);

  useEffect(() => {
    if (Array.isArray(orderData) && orderData.length > 0) {
      const totalSum = orderData.reduce((sum, item) => {
        const itemTotal = parseFloat(item.price) * parseFloat(item.quantity);
        return sum + (isNaN(itemTotal) ? 0 : itemTotal); // Avoid NaN issues
      }, 0);

      setTotalSum(totalSum);
    } else {
      console.log("orderData is not an array or is empty", orderData);
    }
  }, [orderData]);

  // values are being added and deducted start
  const initialAmount = 0 + 0; // Sum of the two values
  const deductions =
    (Number(Discounted_Amount) || 0) +
    (Number(free_pkts_discount) || 0) +
    (Number(company_scheme_disc) || 0);

  const finalValue = totalSum + initialAmount - deductions; // Calculate the final amount

  // values are being added and deducted end

  // converting final values into text/words start
  // Extract integer and decimal parts
  const integerPart = Math.floor(finalValue);
  const decimalPart = Math.round((finalValue - integerPart) * 100);

  // Convert numbers to words
  const integerWords = toWords(integerPart);
  const decimalWords = decimalPart > 0 ? toWords(decimalPart) : "";

  const amountInWords = `INR ${integerWords.replace(/\b\w/g, (c) =>
    c.toUpperCase()
  )} ${decimalPart > 0 ? `And ${decimalWords} Paise` : ""} Only.`;

  // converting final values into text/words end

  // Sample styles
  const styles = StyleSheet.create({
    page: { padding: 10 },
    header: { fontSize: 18, textAlign: "center", marginBottom: 8 },
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 3,
    },
    columnLeft: {
      width: "70%",
      fontSize: 9,
      lineHeight: 1,
      fontWeight: "bold",
    },
    columnRight: {
      width: "30%",
      fontSize: 9,
      lineHeight: 1,
      textAlign: "right",
    },

    table: {
      marginTop: 8,
      borderWidth: 1,
      borderColor: "grey",
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
    },
    tableCol: {
      borderRightWidth: 1,
      borderRightColor: "#000",
      justifyContent: "center",
      padding: 3,
    },
    tableCell: { fontSize: 9, textAlign: "center" },
    footer: { fontSize: 9, marginTop: 8 },
    footerNote: { fontSize: 9, textAlign: "center", marginTop: 3 },
    declaration: {
      fontSize: 9,
      marginTop: 20,
      textAlign: "center",
      fontStyle: "italic",
    },
  });

  // PDF Document Component

  const InvoiceDocument = () => {
    return (
      <Document>
        <Page size={{ width: 595.28, height: 480.89 }} style={styles.page}>
          {/* Header Section */}
          <Text style={styles.header}>Tax Invoice</Text>
          {/* Header Section */}
          <View style={styles.row}>
            <View style={styles.columnLeft}>
              <Text style={{ fontWeight: "bold" }}>
                {SummaryInfo?.stockistname || ""}
              </Text>
              <Text>---</Text>
              <View style={{ gap: 3 }}>
                <Text>FSSAI No : {SummaryInfo?.p_fssai_no || "..."}</Text>
                <Text>GSTIN/UIN : {SummaryInfo?.p_gst_no || "..."}</Text>
              </View>

              {/* <Text>State Name: ..., Code: ...</Text> */}
              {/* <Text>Email: ---</Text> */}
            </View>
            <View style={styles.columnRight}>
              <Text style={{ fontWeight: "bold" }}>
                Invoice No. : {additionalInfo?.invoice_number || ""}
              </Text>
              <Text>Delivery Note :</Text>
              <Text>Dispatch Doc No :</Text>
              <Text>Dispatched through :</Text>
            </View>
            <View style={styles.columnRight}>
              <Text>Dated : {additionalInfo?.created_at || "..."}</Text>
              <Text>{"\u00A0\u00A0\u00A0\u00A0"}</Text>
              <Text>Delivery Note Date :</Text>
              <Text>Destination :</Text>
            </View>
          </View>

          {/* Buyer Section */}
          <View style={[styles.columnLeft, { marginBottom: 2 }]}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontWeight: "bold" }}>Buyer (Bill to) :</Text>
              <Text style={{ marginLeft: 8 }}>
                {SummaryInfo?.retailername || "..."}
              </Text>
            </View>

            <View style={{ gap: 3 }}>
              <Text>FSSAI No : {SummaryInfo?.r_fssai_no || "..."}</Text>
              <Text>GSTIN/UIN : {SummaryInfo?.r_gst_no || "..."}</Text>
            </View>
            {/* <Text>State Name: ..., Code: ...</Text> */}
            <Text>Contact : ...</Text>
          </View>

          {/* Table Header */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>S No.</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>Description of Goods</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>HSN/SAC</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>GST</Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>Quantity</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>Per</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>Rate</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>Rate (Incl. of Tax)</Text>
              </View>

              {/* New Column */}

              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>Disc %</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>Amount</Text>
              </View>
            </View>
            {/* Table Rows */}
            {orderData && Array.isArray(orderData) && orderData.length >= 0 ? (
              orderData.map((item, index) => {
                const baseRate = parseFloat(item.price); // Base rate (price)
                const taxPercentage = parseFloat(item.tax); // Tax percentage
                const rateInclTax = baseRate * (1 + taxPercentage / 100); // Inclusive rate calculation

                return (
                  <View style={styles.tableRow} key={index}>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                      <Text style={styles.tableCell}>{index + 1}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "20%" }]}>
                      <Text style={styles.tableCell}>{item._id}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={styles.tableCell}>
                        {item.hsn_sac || "-"}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                      <Text style={styles.tableCell}>{taxPercentage}%</Text>
                    </View>
                    <View
                      style={[
                        styles.tableCol,
                        { width: "20%", flexDirection: "row" },
                      ]}
                    >
                      <Text style={styles.tableCell}>{item.quantity}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                      <Text style={styles.tableCell}>{item.unit}</Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={styles.tableCell}>{item.price}</Text>{" "}
                      {/* Display inclusive rate */}
                    </View>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={styles.tableCell}>
                        {rateInclTax.toFixed(2)}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "5%" }]}>
                      <Text style={styles.tableCell}>
                        {item.cash_disc || "-"}
                      </Text>
                    </View>
                    <View style={[styles.tableCol, { width: "10%" }]}>
                      <Text style={styles.tableCell}>
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(item.price * item.quantity)}
                      </Text>
                    </View>
                  </View>
                );
              })
            ) : (
              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>No data is available</Text>
              </View>
            )}

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>Gross Total</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>-</View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>-</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              >
                -
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>-</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>-</Text>
              </View>
              {/* New Column Data */}
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>-</Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>-</Text>
              </View>

              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(totalSum)}
                </Text>
              </View>
            </View>

            {/* Second row */}

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}></Text>
              </View>

              <View style={[styles.tableCol, { width: "20%" }]}>
                <Text style={styles.tableCell}>CGST</Text>
                <Text style={styles.tableCell}>SGST</Text>
                <Text style={styles.tableCell}>Less: Round Off </Text>
                <Text style={styles.tableCell}>Less: CD </Text>
                <Text style={styles.tableCell}>Less: Free Pkts </Text>
                <Text style={styles.tableCell}>Less: CS Disc </Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>Net Total</Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}></View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  { width: "20%", flexDirection: "row" },
                ]}
              ></View>
              <View style={[styles.tableCol, { width: "5%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              {/* New Column Data */}
              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: "5%" }]}></View>

              <View style={[styles.tableCol, { width: "10%" }]}>
                <Text style={styles.tableCell}>00.00</Text>
                <Text style={styles.tableCell}>00.00</Text>
                <Text style={styles.tableCell}>(-) 00.00</Text>
                <Text style={styles.tableCell}>(-) {Discounted_Amount}.00</Text>
                <Text style={styles.tableCell}>
                  (-) {free_pkts_discount}.00
                </Text>
                <Text style={styles.tableCell}>
                  (-) {company_scheme_disc}.00
                </Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>
                  {"\u00A0\u00A0\u00A0\u00A0"}
                </Text>
                <Text style={styles.tableCell}>
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(finalValue)}
                </Text>
              </View>
            </View>
          </View>

          {/* Footer Section */}
          <Text style={styles.footer}>
            Amount Chargeable (in words): {amountInWords}
          </Text>

          {/* Declaration */}
          <Text style={styles.declaration}>
            Please check all the goods rate before acknowledging invoice.
          </Text>
          <Text style={styles.footerNote}>
            acknowledged by Stockiest : ___________
          </Text>
        </Page>
      </Document>
    );
  };

  // For Excel export code

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = Array(50)
      .fill([])
      .map(() => Array(15).fill(""));

    // Tax Invoice Heading
    sheetData[0][0] = "TAX INVOICE";

    // Header Information
    sheetData[1][0] = "GANESH AGENCY, NIGADI";
    sheetData[2][0] = "FSSAI No: 0";
    sheetData[3][0] = "GSTIN/UIN: 0";

    // Invoice Metadata
    sheetData[1][7] = "Invoice No. 12038224032025092819607";
    sheetData[1][8] = "Dated: 24-Mar-2025";

    sheetData[2][7] = "Delivery Note";
    sheetData[2][8] = "--";
    sheetData[3][7] = "Dispatch Doc No.";
    sheetData[3][8] = "Delivery Note Date";
    sheetData[4][7] = "Dispatched through";
    sheetData[4][8] = "Destination";

    // Buyer Information
    sheetData[8][0] = "Buyer (Bill to): Jogmaya Provision Store";
    sheetData[9][0] = "FSSAI No: ...";
    sheetData[10][0] = "GSTIN/UIN: ...";
    sheetData[11][0] = "Contact: ...";

    // Itemized Table Header
    const itemHeaders = [
      "S No.",
      "Description of Goods",
      "HSN/SAC",
      "GST",
      "Quantity",
      "Per",
      "Rate",
      "Rate (Incl. of Tax)",
      "Disc %",
      "Amount",
    ];
    sheetData[15] = itemHeaders;

    // Itemized Rows
    const items = [
      [
        1,
        "PFN10RP",
        "-",
        "5%",
        "3 Pkt",
        "Pkt",
        "260.00",
        "273.00",
        "0",
        "780.00",
      ],
      [
        2,
        "SHE10RP",
        "-",
        "5%",
        "2 Pkt",
        "Pkt",
        "180.15",
        "189.16",
        "0",
        "360.30",
      ],
    ];

    items.forEach((item, index) => {
      sheetData[16 + index] = item;
    });

    // Totals Section
    sheetData[20][7] = "Gross Total";
    sheetData[20][9] = "₹ 1,140.30";

    sheetData[22][7] = "CGST";
    sheetData[22][9] = "₹ 123.57";
    sheetData[23][7] = "SGST";
    sheetData[23][9] = "₹ 123.57";
    sheetData[24][7] = "Less: Round Off";
    sheetData[24][9] = "(-) 00.00";

    sheetData[25][7] = "Less: CD";
    sheetData[25][9] = "(-) 00.00";
    sheetData[26][7] = "Less: Free Pkts";
    sheetData[26][9] = "(-) 00.00";
    sheetData[27][7] = "Less: CS Disc";
    sheetData[27][9] = "(-) 00.00";

    sheetData[29][7] = "Net Total";
    sheetData[29][9] = "₹ 1,387.44";

    // Amount in Words
    sheetData[31][0] = "Amount Chargeable (in words)";
    sheetData[32][0] =
      "INR One Thousand, Three Hundred Eighty-Seven And Forty-Four Paise Only";

    // Declaration
    sheetData[34][0] = "Declaration";
    sheetData[35][0] =
      "We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.";
    sheetData[36][0] = "This is a Computer Generated Invoice";

    // Merging Cells
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // "Tax Invoice" across 5 columns
      { s: { r: 7, c: 0 }, e: { r: 7, c: 4 } }, // "Buyer (Bill to)" across 5 columns
      { s: { r: 27, c: 0 }, e: { r: 27, c: 4 } }, // "Amount Chargeable (in words)"
      { s: { r: 31, c: 0 }, e: { r: 31, c: 4 } }, // "Declaration"
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    worksheet["!merges"] = merges;

    // Export
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");
    XLSX.writeFile(
      workbook,
      `Excel_invoice:${additionalInfo?.invoice_number}.xlsx`
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={true}
      fullScreen={fullscreen}
    >
      <DialogTitle
        id="customized-dialog-title"
        // sx={{ bgcolor: "#5052ba", color: "white" }}
        sx={{ bgcolor: MyBgColor ? MyBgColor : "#5052ba", color: "white" }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexWrap: "nowrap" }}
        >
          {/* Title on the left */}
          <Grid
            item
            xs={9}
            sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, textAlign: "left" }}
          >
            {title}
          </Grid>

          {/* PDF and Excel buttons on the right */}
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1, // Adds gap between buttons
            }}
            pr={3}
          >
            {PDFData ? (
              <PDFDownloadLink
                document={<InvoiceDocument />}
                fileName={`PDF_invoice:${additionalInfo?.invoice_number}.pdf`}
              >
                {({ loading }) => (
                  <Button
                    sx={{
                      color: "white",
                      border: "1px solid #c7b4b4",
                    }}
                    onClick={PDF_GeneraTor} // Attach your function here
                  >
                    {loading ? "Loading document..." : PDFData}
                  </Button>
                )}
              </PDFDownloadLink>
            ) : null}

            {ExcelData ? (
              <Button
                sx={{
                  color: "white",
                  border: "1px solid #c7b4b4",
                }}
                disabled={true}
                onClick={() => handleExport()}
              >
                {ExcelData}
              </Button>
            ) : null}
          </Grid>
        </Grid>

        {/* Close Function code start */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 14,
            color: "#ff0000fa",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* Close Function code end */}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ paddingTop: "2px" }}>{children}</DialogContent>
      <Divider />
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  fullscreen: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([
    PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    PropTypes.bool,
  ]),
};

export default CustomDialog;
