import React, { useEffect, useState } from "react";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import { Autocomplete, Box, Button, TextField, Checkbox } from "@mui/material";
import JSZip from "jszip";
import * as XLSX from "xlsx";
import {
  getRequestForDownload,
  getRequestInstanceOne,
} from "../../../apiServices/apiCall";
import toast, { Toaster } from "react-hot-toast";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { axiosInstance1 } from "../../../apiServices/axiosInstance";
import API_ENDPOINTS from "../../../apiServices/apiEndpoints";

// Icons for checkboxes
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExportExcel = ({ setOpenDialog }) => {
  const [filterValue1, setFilterValue1] = useState("");
  const [filterValue2, setFilterValue2] = useState([]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [filterValue3, setFilterValue3] = useState("all_order"); // Added state for third filter value
  const [options, setOptions] = useState([]);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [workingDays, setWorkingDays] = useState(null);
  const userRole = localStorage.getItem("user");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setCustomDateRange({ start_date: "", end_date: "" });
  }, []);

  const fetchData = async (selectedValue) => {
    setIsLoading(true);
    try {
      let params = new URLSearchParams();

      if (localStorage.getItem("user") !== "admin") {
        params.append("user", localStorage.getItem("user"));
      } else {
        params.append("key", selectedValue);
      }

      const response = await getRequestInstanceOne(
        `/api/get_all_list/?${params.toString()}&start_date=${
          customDateRange.start_date
        }&end_date=${customDateRange.end_date}`
      );

      const sortedData = response.data.sort((a, b) => {
        const nameA = (a.name || a._id || "").toLowerCase();
        const nameB = (b.name || b._id || "").toLowerCase();
        // Ensure "ALL" is always on top
        if (nameA === "all") return -1; // a comes before b
        if (nameB === "all") return 1; // b comes before a
        return nameA.localeCompare(nameB);
      });

      setOptions([...sortedData]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filterValue1);
  }, [filterValue1]);

  const handleDateChange = (field) => (event) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();
    const otherDate = new Date(
      field === "start_date"
        ? customDateRange.end_date
        : customDateRange.start_date
    );

    // Check for invalid date
    if (selectedDate == "Invalid Date") {
      setIsDateValid(false); // Disable Submit button if date is invalid
    } else {
      // If the date is valid, set the validity state to true
      setIsDateValid(true);
    }

    // Validate future dates
    if (selectedDate > currentDate) {
      toast.error(
        `${
          field === "start_date" ? "Start date" : "End date"
        } cannot be greater than the current date.`
      );
      return;
    }

    // Cross-validate start_date and end_date
    const isStartDateInvalid =
      field === "start_date" && otherDate && selectedDate > otherDate;
    const isEndDateInvalid =
      field === "end_date" && otherDate && selectedDate < otherDate;

    if (isStartDateInvalid || isEndDateInvalid) {
      toast.error(
        `${
          field === "start_date" ? "Start date" : "End date"
        } is invalid compared to ${
          field === "start_date" ? "end date" : "start date"
        }.`
      );
      return;
    }

    // Update the custom date range state
    setCustomDateRange({
      ...customDateRange,
      [field]: event.target.value,
    });
  };

  const getWorkingDays = async (id) => {
    const { start_date, end_date } = customDateRange;

    // Early return if start_date or end_date is missing
    if (!start_date || !end_date) {
      console.error("Start date or end date is missing");
      return;
    }

    const params = new URLSearchParams({
      from_date: start_date,
      to_date: end_date,
      empId: id,
    });

    const url = `http://143.244.133.43:5000/api/expense/working_days_range?${params.toString()}`;

    try {
      const response = await fetch(url);

      // Check if response is successful
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();

      // Validate if result and id exist
      const fetchedWorkingDays = data.result?.[id]?.working_days;

      if (fetchedWorkingDays !== undefined) {
        setWorkingDays(fetchedWorkingDays); // Update the state
      } else {
        console.error(`No working days found for empId: ${id}`);
      }
    } catch (error) {
      console.error("Error fetching working days:", error.message);
    }
  };

  const handleSubmit = async () => {
    // await getWorkingDays(id); // Get working days before exporting Excel
    if (workingDays !== null) {
      exportExcel(workingDays); // Pass workingDays to exportExcel
    } else {
      exportExcel();
    }
  };

  const exportExcel = async (workingDays) => {
    setIsLoading(true);
    try {
      const endpoint = API_ENDPOINTS.all_order_excel;

      let filterValue2Payload;

      if (filterValue2 === "All") {
        // If filterValue2 is "All", always send it as a single string
        filterValue2Payload = "All";
      } else if (filterValue1 === "sku") {
        // If filterValue1 is "sku", send filterValue2 as an array
        filterValue2Payload = Array.isArray(filterValue2)
          ? filterValue2
          : [filterValue2];
      } else {
        // For other filterValue1 values (e.g., "salesgroup", "name", "payerId"), send filterValue2 as a single string
        filterValue2Payload = Array.isArray(filterValue2)
          ? filterValue2[0] // Take the first element if it's an array
          : filterValue2; // Otherwise, use the value directly
      }

      // Construct the request body as a JSON object
      const body = {
        [filterValue1]: filterValue2Payload, // Dynamic key based on filterValue1
        type: filterValue3, // Assuming filterValue3 is properly set
        start_date: customDateRange.start_date,
        end_date: customDateRange.end_date,
        ...(userRole !== "admin" ? { user: userRole } : {}),
      };

      // Add "working_days" to the body if filterValue1 is "name"
      if (filterValue1 === "name") {
        body.working_days = workingDays;
      }

      // Use axiosInstance1 directly to handle response as blob
      const response = await axiosInstance1.post(endpoint, body, {
        responseType: "blob", // Important to handle file download
      });

      const res = response.data; // axios automatically extracts `data`
      const downloadUrl = window.URL.createObjectURL(res);

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${
        filterValue2 === "All"
          ? "All"
          : Array.isArray(filterValue2)
          ? filterValue2.join("_")
          : filterValue2
      }_${filterValue3}_${customDateRange.start_date}_${
        customDateRange.end_date
      }.xlsx`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
      setOpenDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterValue1Change = (event, newValue) => {
    setFilterValue1(newValue);
  };

  const handleFilterValue2Change = (event, newValue) => {
    if (Array.isArray(newValue)) {
      // Check if the selected values include "All"
      if (newValue.some((item) => item.name === "All" || item._id === "All")) {
        // If "All" is selected, set filterValue2 to "All"
        setFilterValue2("All");
      } else {
        // Otherwise, extract the relevant property (e.g., `name`, `_id`, etc.)
        const selectedValues = newValue.map(
          (item) => item.name || item._id || item.salesgroup || item.sku
        );
        setFilterValue2(selectedValues); // Update filterValue2 with an array of selected values
      }
    } else if (newValue) {
      // If a single value is selected, handle it
      if (filterValue1 === "sku") {
        // For "sku", always treat filterValue2 as an array
        setFilterValue2([
          newValue.name || newValue._id || newValue.salesgroup || newValue.sku,
        ]);
      } else {
        // For other filterValue1 values, treat filterValue2 as a single value
        setFilterValue2(
          newValue.name || newValue._id || newValue.salesgroup || newValue.sku
        );
      }
    } else {
      // If no value is selected, reset filterValue2 to an empty array
      setFilterValue2([]);
    }
  };

  // Handle selection change for the third dropdown
  const handleFilterValue3Change = (event, newValue) => {
    setFilterValue3(newValue); // Update filterValue3 state
  };

  const isDateRangeSelected =
    customDateRange.start_date && customDateRange.end_date;

  const handleBothFunctions = (event, value) => {
    if (value) {
      handleFilterValue2Change(null, value); // Update filterValue2 with selected values

      // Call getWorkingDays for each selected value
      value.forEach((item) => {
        if (item.empid) {
          getWorkingDays(item.empid);
        }
      });
    }
  };

  return (
    <>
      <Toaster />
      <CustomLoader open={isLoading} />

      <TextField
        margin="dense"
        label="Start Date"
        type="date"
        fullWidth
        value={customDateRange.start_date}
        onChange={handleDateChange("start_date")}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        margin="dense"
        label="End Date"
        type="date"
        fullWidth
        value={customDateRange.end_date}
        onChange={handleDateChange("end_date")}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <div style={{ display: "flex", marginBottom: "16px" }}>
        {localStorage.getItem("user")?.trim() === "admin" && (
          <Autocomplete
            disabled={!isDateRangeSelected}
            disableClearable
            size="small"
            id="combo-box-demo-1"
            options={["salesgroup", "payerId", "name", "sku"]}
            value={filterValue1}
            onChange={handleFilterValue1Change}
            getOptionLabel={(option) => option}
            sx={{ width: 430, marginRight: "16px", marginTop: "5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Group by"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        {localStorage.getItem("user")?.trim() != "admin" && (
          <Autocomplete
            disableClearable
            size="small"
            id="combo-box-demo-1"
            options={["name"]}
            value={filterValue1}
            onChange={handleFilterValue1Change}
            getOptionLabel={(option) => option}
            sx={{ width: 430, marginRight: "16px", marginTop: "5px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Group by"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        {localStorage.getItem("user")?.trim() === "admin" && (
          <Autocomplete
            disabled={!isDateRangeSelected}
            multiple
            disableCloseOnSelect={filterValue1 == "sku"}
            disableClearable
            size="small"
            id="combo-box-demo-2"
            options={options}
            getOptionLabel={(option) => option._id || option.name}
            onChange={handleBothFunctions}
            limitTags={2} // Limit the number of visible tags
            sx={{ width: 430, marginTop: "5px", marginRight: "16px" }}
            renderOption={
              filterValue1 === "sku"
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option._id || option.name}
                    </li>
                  )
                : undefined // Do not render checkboxes for non-"sku" cases
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        {localStorage.getItem("user")?.trim() != "admin" && (
          <Autocomplete
            disableClearable
            multiple
            disableCloseOnSelect={filterValue1 == "sku"}
            size="small"
            id="combo-box-demo-2"
            options={options}
            getOptionLabel={(option) => option._id || option.name}
            limitTags={2} // Limit the number of visible tags
            onChange={handleBothFunctions}
            sx={{ width: 430, marginTop: "5px", marginRight: "16px" }}
            renderOption={
              filterValue1 !== "sku"
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option._id || option.name}
                    </li>
                  )
                : undefined // Do not render checkboxes for "sku"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}

        <Autocomplete
          disabled={!isDateRangeSelected}
          disableClearable
          size="small"
          id="combo-box-demo-3"
          options={
            filterValue1 === "sku"
              ? ["order", "all_order", "cancel"]
              : ["order", "no_order", "all_order", "cancel"]
          }
          value={"all_order"}
          getOptionLabel={(option) => option}
          onChange={handleFilterValue3Change}
          sx={{ width: 430, marginTop: "5px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select type"
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      </div>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => setOpenDialog(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#5052ba",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#4042a3",
            },
            ml: 2,
          }}
          disabled={!isDateValid}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

export default ExportExcel;
