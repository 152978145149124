import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { getRequestInstanceOne } from "../../../apiServices/apiCall";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import ReactApexChart from "react-apexcharts";

const AreaWiseWeeklySalesBarChart = ({
  selectedSalesgroup,
  selectedStockist,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: { show: true },
        zoom: { enabled: true },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 4,
          dataLabels: {
            total: {
              enabled: true,
              style: { fontSize: "13px", fontWeight: 900 },
            },
          },
        },
      },
      xaxis: {
        categories: [],
        labels: { show: true }, // Hide x-axis labels
      },
      yaxis: {
        title: { text: "Sales Group" },
        labels: { show: true }, // Hide y-axis labels
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      fill: { opacity: 1 },
      dataLabels: {
        enabled: false, // Disable data labels on bars
      },
    },
    series: [],
  });

  const fetchChartData = async () => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams();

      if (selectedSalesgroup) {
        params.append("salesgroup", selectedSalesgroup);
      }

      if (selectedStockist && localStorage.getItem("user") === "admin") {
        params.append("stockist", selectedStockist);
      }

      if (localStorage.getItem("user") !== "admin") {
        params.append("user", selectedStockist);
      }

      const response = await getRequestInstanceOne(
        `/api/week-days?${params.toString()}`
      );
      if (response && response.data) {
        const data = response.data;

        const categories = data.map((group) => group.salesgroup);

        const dayOrder = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];

        const allDays = new Set();
        data.forEach((group) => {
          group.days.forEach((day) => {
            allDays.add(day.day_name);
          });
        });

        const dayNames = Array.from(allDays).sort(
          (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
        );

        const series = dayNames.map((dayName) => ({
          name: dayName,
          data: data.map((group) => {
            const day = group.days.find((d) => d.day_name === dayName);
            return day ? day.day_revenue : 0;
          }),
        }));

        setChartData({
          options: {
            ...chartData.options,
            xaxis: {
              title: { text: "Revenue" },
              categories,
            },
          },
          series,
        });
      } else {
        console.error("Invalid API response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [selectedSalesgroup, selectedStockist]);

  return (
    <div>
      <div id="chart">
        <Grid>
          <Typography variant="h6">Last 7 Days Revenue</Typography>
        </Grid>
        {isLoading ? (
          <CustomLoader />
        ) : chartData.series && chartData.series.length > 0 ? (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={450}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default AreaWiseWeeklySalesBarChart;
