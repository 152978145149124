import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import axios from "axios";
import Cookies from "js-cookie"; // Import js-cookie

const LoginCard = styled(Card)({
  maxWidth: 400,
  width: "100%",
  height: "auto",
  padding: "1rem",
  borderRadius: "12px",
  boxShadow: "1px 2px 4px 0px #726e6e",
  backgroundColor: "transparent",
});

const Login = ({ setToken }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [credential, setCredential] = useState("");
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/; // Must start with 6-9 and be exactly 10 digits
    const invalidNumbers = ["0000000000", "1111111111", "1234567890"];

    return phoneRegex.test(number) && !invalidNumbers.includes(number);
  };

  const handleLogin = async () => {
    // Validate phone number for non-admin users
    if (username !== "admin" && !isValidPhoneNumber(credential)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    setIsLoading(true); // Show loader when login starts

    try {
      const requestBody =
        username === "admin"
          ? { username, password: credential }
          : { username, phone: credential };

      const response = await fetch("http://143.244.136.36:8084/api/login-jwt", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
        credentials: "include", // 🔹 Ensures refresh token is stored in HTTP-only cookies
      });

      axios.defaults.baseURL = response;
      axios.defaults.withCredentials = true;

      const data = await response.json();

      if (response.ok && data.access_token) {
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("refreshToken", data.refresh_token);
        localStorage.setItem("user", username); // Store username for reference
        setToken(data.access_token);
        navigate("/dashboard");
      } else if (response.status === 401) {
        toast.error(data.message || "Unauthorized: Incorrect credentials.");
      } else {
        setIsOtpSent(true);
        toast.success("OTP sent to your phone number.");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed. Please check credentials.");
    } finally {
      setIsLoading(false); // Hide loader after request is complete
    }
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true); // Show loader while verifying OTP

    try {
      const response = await fetch(
        "http://143.244.136.36:8084/api/verify-otp",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ phone: credential, otp }),
          credentials: "include", // 🔹 Ensures refresh token is stored in HTTP-only cookies
        }
      );

      const data = await response.json();

      if (response.ok && data.access_token) {
        localStorage.setItem("accessToken", data.access_token);
        localStorage.setItem("user", username); // Store username for reference
        setToken(data.access_token);
        navigate("/dashboard");
      } else {
        toast.error(data.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      toast.error("OTP verification failed.");
    } finally {
      setIsLoading(false); // Hide loader after request completes
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      isOtpSent ? handleVerifyOtp() : handleLogin();
    }
  };

  return (
    <>
      {isLoading && <CustomLoader open={isLoading} />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          background: `radial-gradient(circle, rgb(250 215 140) 0%, rgb(135 185 185) 100%)`,
        }}
      >
        <LoginCard>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/sapat-logo.png"
              alt="Sapat Logo"
              style={{ height: 80, marginBottom: "1.5rem" }}
            />
            <TextField
              autoComplete="off"
              size="small"
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value.slice(0, 10))}
              onKeyDown={handleKeyDown}
              inputProps={{ maxLength: 10 }}
            />
            {!isOtpSent ? (
              <TextField
                autoComplete="off"
                size="small"
                label={username === "admin" ? "Password" : "Phone Number"}
                type={
                  username === "admin" && !showPassword ? "password" : "text"
                }
                variant="outlined"
                margin="normal"
                fullWidth
                value={credential}
                onChange={(e) => setCredential(e.target.value)}
                onKeyDown={handleKeyDown}
                inputProps={
                  username === "admin"
                    ? { maxLength: 10 }
                    : { inputMode: "numeric", pattern: "[0-9]*", maxLength: 10 }
                }
                InputProps={
                  username === "admin"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                    : {}
                }
              />
            ) : (
              <TextField
                autoComplete="off"
                size="small"
                label="Enter OTP"
                variant="outlined"
                margin="normal"
                fullWidth
                value={otp}
                onChange={(e) => {
                  const newValue = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 6);
                  setOtp(newValue);
                }}
                inputProps={{ maxLength: 6 }}
                onKeyDown={handleKeyDown}
              />
            )}
            <Button
              variant="contained"
              sx={{ mt: 2, backgroundColor: "rgb(227, 30, 36)", color: "#fff" }}
              onClick={isOtpSent ? handleVerifyOtp : handleLogin}
              fullWidth
              disabled={
                !(
                  username &&
                  (username === "admin"
                    ? credential === "sapat@1234"
                    : /^\d{10}$/.test(credential))
                )
              }
            >
              {username === "admin"
                ? isOtpSent
                  ? "Verify OTP & Login"
                  : "Login"
                : isOtpSent
                ? "Verify OTP"
                : "Get OTP"}
            </Button>
            <Toaster>{(t) => <ToastBar toast={t} />}</Toaster>
          </CardContent>
        </LoginCard>
      </Box>
    </>
  );
};

export default Login;
