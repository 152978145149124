import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // ✅ Import Close Icon
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function FileUploadExport_dbf() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const htmlContent = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>File Upload & Export</title>
    <link rel="stylesheet" href="style.css" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
      rel="stylesheet"
    />
    <style>
  .year-dropdown {
  position: relative;
}
.year-selection {
  display: none;
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}
.year-selection.active {
  display: block;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-left: 10px;
}
.my_color {
  color: darkgrey;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
  </head>
  <body class="bg-light">
    <div class="container-fluid mt-4">
      <!-- Import Section -->
      <h4 class="fw-bold">Import</h4>
      <div class="row">
        <div class="col-md-10">
          <input
            type="file"
            class="form-control"
            accept=".dbf,.xls,.xlsx"
            id="inputGroupFile"
          />
        </div>
        <div class="col-md-2">
          <button class="btn btn-dark w-100" id="uploadSubmit">
            <span
              id="uploadLoader"
              class="spinner-border spinner-border-sm"
              style="display: none"
            ></span>
            Submit
          </button>
        </div>
      </div>
      <!-- Export Section -->
      <h4 class="fw-bold mt-4 my_color">Export</h4>

      <div class="row">
        <!-- ENT_TYPE Dropdown -->

        <!-- Year Selection (Expandable) -->
        <div class="col-md-3 year-dropdown">
          <label class="fw-bold">Select Year(s)</label>
          <div class="year-dropdown">
            <button class="form-control text-start" id="yearSelectButton">
              Select Year(s)
            </button>
            <div id="yearSelection" class="year-selection">
              <div id="yearCheckboxContainer"></div>
              <button class="btn btn-dark w-100 mt-2" id="doneSelectYear">
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2 mt-3 align-items-center">
        <button class="btn btn-dark w-100" id="exportSubmit">
          Update Excel
        </button>
        <div id="exportLoader" style="display: none; margin-left: 10px"></div>
      </div>

      <div id="file-content" class="mt-3"></div>
    </div>
    <script>
    document.getElementById("uploadSubmit").addEventListener("click", async () => {
  const fileInput = document.getElementById("inputGroupFile");
  const file = fileInput.files[0];
  const uploadButton = document.getElementById("uploadSubmit");
  const uploadLoader = document.getElementById("uploadLoader");
  const exportButton = document.getElementById("exportSubmit");

  if (!file) {
    alert("❌ Please select a file to upload.");
    return;
  }

  // Disable both buttons during upload
  uploadButton.disabled = true;
  exportButton.disabled = true;
  uploadLoader.style.display = "inline-block";
  uploadButton.innerHTML = '<span class="spinner-border spinner-border-sm"></span> Processing...';

  try {
    // Properly append file to FormData
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      "http://203.187.221.87/offer/import_dbf/",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text(); // Get the actual error message
    throw new Error("Server error: " + response.status + " - " + errorText);
    }

    const result = await response.json();
    document.getElementById("file-content").innerText =
      "✅ File uploaded successfully! Server Response: " +
      JSON.stringify(result);
  } catch (error) {
    console.error("❌ Error uploading the file:", error);
    document.getElementById("file-content").innerText =
      "❌ Error uploading the file. " + error.message;
  } finally {
    // Enable both buttons again
    uploadButton.disabled = false;
    exportButton.disabled = false;
    uploadLoader.style.display = "none";
    uploadButton.innerHTML = "Submit";
  }
});

// Function to dynamically generate year checkboxes
function populateYearCheckboxes() {
  const startYear = 2016;
  const currentYear = new Date().getFullYear();
  const yearContainer = document.getElementById("yearCheckboxContainer");

  for (let year = startYear; year < currentYear; year++) {
    const yearValue = year + "-" + (year + 1).toString().slice(-2);
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.value = yearValue;
    checkbox.classList.add("year-checkbox");

    // ✅ Select last two years by default
    if (year >= currentYear - 2) {
      checkbox.checked = true;
    }

    const label = document.createElement("label");
    label.classList.add("ms-2");
    label.textContent = yearValue;

    const div = document.createElement("div");
    div.classList.add("form-check");
    div.appendChild(checkbox);
    div.appendChild(label);

    yearContainer.appendChild(div);
  }
}

document.addEventListener("DOMContentLoaded", populateYearCheckboxes);

let selectedYears = [];

// Toggle Year Dropdown
document.getElementById("yearSelectButton").addEventListener("click", () => {
  document.getElementById("yearSelection").classList.toggle("active");
});

// call this function on done btn and on page loads start
function updateYearSelection() {
  const selectedYears = Array.from(
    document.querySelectorAll(".year-checkbox:checked")
  ).map((checkbox) => checkbox.value);

  document.getElementById("yearSelectButton").innerText =
    selectedYears.length === 0 ? "Select Year(s)" : selectedYears.join(", ");

  // Close the dropdown
  document.getElementById("yearSelection").classList.remove("active");
}

// Call the function on page load
document.addEventListener("DOMContentLoaded", updateYearSelection);

// Add event listener for the "doneSelectYear" button click
document
  .getElementById("doneSelectYear")
  .addEventListener("click", updateYearSelection);

// call this function on done btn and on page loads end

// Close year selection if clicked outside
document.addEventListener("click", (event) => {
  if (!event.target.closest(".year-dropdown")) {
    document.getElementById("yearSelection").classList.remove("active");
  }
});

document.getElementById("exportSubmit").addEventListener("click", async () => {
  // Get selected years dynamically from checkboxes
  const selectedYears = Array.from(
    document.querySelectorAll(".year-checkbox:checked")
  ).map((checkbox) => checkbox.value);

  const url = "http://203.187.221.87/offer/excel_primarydbfapi/";

  // Get loader and buttons
  const loader = document.getElementById("exportLoader");
  const exportButton = document.getElementById("exportSubmit");
  const uploadButton = document.getElementById("uploadSubmit");

  // ✅ Validation: Ensure at least 'Year' is selected
  if (selectedYears.length === 0) {
    alert("❌ Please select 'Year'");
    return;
  }

  // ✅ Disable buttons while processing
  loader.style.display = "inline-block";
  exportButton.disabled = true;
  uploadButton.disabled = true;
  exportButton.innerHTML = '<span class="spinner-border spinner-border-sm"></span> Processing...';

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        years: selectedYears,
      }),
    });

    if (!response.ok) {
      throw new Error("Server error: " + response.statusText);
    }

    // ✅ Handle the response data (without downloading a file)
    const result = await response.json();
    console.log("✅ Export Successful! Response:", result);

    document.getElementById("file-content").innerText =
      "✅ Export Successful! Server Response: " + JSON.stringify(result);
  } catch (error) {
    console.error("❌ Error processing export:", error);
    document.getElementById("file-content").innerText =
      "❌ Error processing export. Please try again.";
  } finally {
    // Hide loader and enable buttons
    loader.style.display = "none";
    exportButton.disabled = false;
    exportButton.innerHTML = "Submit Export Data";
    uploadButton.disabled = false;
  }
});

document.getElementById("entType").addEventListener("change", function () {
  const entType = this.value;
  const yearSelectionDiv = document.querySelector(".year-dropdown");
  const fromBillDateDiv = document
    .getElementById("fromBillDate")
    .closest(".col-md-3");
  const toBillDateDiv = document
    .getElementById("toBillDate")
    .closest(".col-md-3");
  const selectionOptionsDiv = document.getElementById("selectionOptions");

  if (entType === "TARGET" || entType === "ALL") {
    yearSelectionDiv.style.display = "block"; // Show Year Selection
    fromBillDateDiv.style.display = "none"; // Hide From Bill Date
    toBillDateDiv.style.display = "none"; // Hide To Bill Date
    selectionOptionsDiv.style.display = "none"; // Hide selection options
  } else if (entType === "NONE") {
    selectionOptionsDiv.style.display = "block"; // Show selection options
    yearSelectionDiv.style.display = "none"; // Hide initially
    fromBillDateDiv.style.display = "none"; // Hide initially
    toBillDateDiv.style.display = "none"; // Hide initially
  } else {
    yearSelectionDiv.style.display = "block"; // Show Year Selection
    fromBillDateDiv.style.display = "block"; // Show From Bill Date
    toBillDateDiv.style.display = "block"; // Show To Bill Date
    selectionOptionsDiv.style.display = "none"; // Hide selection options
  }
});

// Handle selection option change (Year or Bill Date for NONE)
document.querySelectorAll("input[name='selectionType']").forEach((radio) => {
  radio.addEventListener("change", function () {
    const yearSelectionDiv = document.querySelector(".year-dropdown");
    const fromBillDateDiv = document
      .getElementById("fromBillDate")
      .closest(".col-md-3");
    const toBillDateDiv = document
      .getElementById("toBillDate")
      .closest(".col-md-3");

    if (this.value === "year") {
      yearSelectionDiv.style.display = "block"; // Show Year Selection
      fromBillDateDiv.style.display = "none"; // Hide From Bill Date
      toBillDateDiv.style.display = "none"; // Hide To Bill Date
    } else if (this.value === "billdate") {
      yearSelectionDiv.style.display = "none"; // Hide Year Selection
      fromBillDateDiv.style.display = "block"; // Show From Bill Date
      toBillDateDiv.style.display = "block"; // Show To Bill Date
    }
  });
});

// Trigger change event to apply visibility on page load
document.getElementById("entType").dispatchEvent(new Event("change"));

    
    </script>
  </body>
</html>
 
  `;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Button
        variant="contained"
        onClick={handleClickOpen}
        style={{
          borderRadius: "0 20px 20px 0",
          padding: "10px 20px",
          minWidth: "250px",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          background: "#5051ba",
          textTransform: "none", // ✅ Prevents uppercase transformation
        }}
      >
        <span
          style={{
            color: "lawngreen",
            border: "1px solid",
            padding: "0px 5px",
          }}
        >
          Open a Modal
        </span>
        To Import DBF File
        <ArrowForwardIcon />
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle style={{ color: "#686ad5", textAlign: "center" }}>
          File Upload & Export
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "red" }} // ✅ Positions it in top-right
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <iframe
            title="Upload & Export"
            className="w-full h-[500px] border rounded"
            srcDoc={htmlContent}
            sandbox="allow-scripts allow-modals" // Add "allow-modals" to enable alert()
            style={{ width: "100%", height: "500px", border: "none" }}
          ></iframe>
        </DialogContent>
      </Dialog>
    </div>
  );
}
