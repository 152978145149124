import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import NewOfferForm from "./NewOfferForm";
import OfferTypeForm from "./OfferTypeForm";

const TabbedForms = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        p: 0,
      }}
    >
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{
          mb: 1,
          "& .MuiTabs-flexContainer": {
            borderBottom: "2px solid transparent",
          },
          "& .MuiTabs-indicator": {
            bottom: "5px",
          },
          "& .MuiTab-root": {
            textTransform: "none",
            fontSize: "13px",
            fontWeight: "bold",
            borderRadius: "8px 8px 0 0",
            backgroundColor: "#f5f5f5",
            marginRight: "8px",
            padding: "10px 20px",
            transition: "all 0.3s",
            border: "1px solid gray",
            minHeight: "28px", // ✅ Override min-height
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
            "&.Mui-selected": {
              backgroundColor: "#5052ba",
              color: "#fff",
            },
          },
        }}
      >
        <Tab label="General" />
        <Tab label="Dates" />
        <Tab label="Upload" disabled="true" />
        <Tab label="Settings" disabled="true" />
      </Tabs>
      <Box
        sx={{
          width: "100%",
          p: 2,
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {tabValue === 0 && <NewOfferForm />} {/* 🟢 First form in General */}
        {tabValue === 1 && <OfferTypeForm />} {/* 🟢 Second form in Dates */}
        {tabValue === 2 && <div> {/* Upload Tab Content */} </div>}
        {tabValue === 3 && <div> {/* Settings Tab Content */} </div>}
      </Box>
    </Box>
  );
};

export default TabbedForms;
