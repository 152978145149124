import React, { useState, useEffect, useCallback, useRef } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DeleteForeverOutlined } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  TablePagination,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  Autocomplete,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import ImageIcon from "@mui/icons-material/Image";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { green } from "@mui/material/colors";
import toast, { Toaster } from "react-hot-toast";
import API_ENDPOINTS from "../../../../apiServices/apiEndpoints";
import { useSelector, useDispatch } from "react-redux";
import {
  updateSelectedFilterAllOrder,
  updateCustomDateRangeAllOrder,
} from "../../../../feature/dateFilter/dateFilterSlice";
import {
  getRequestInstanceOne,
  postRequest,
  putRequest,
} from "../../../../apiServices/apiCall";
import { CustomLoader } from "../../../../components/CustomLoader/CustomLoader";
import CustomDialog from "../../../../components/CustomDialog/CustomDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { remove } from "jszip";

// Style start

const StyledTableCell = styled(TableCell)(({ theme, isHeader }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: isHeader ? "center" : "left",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
  padding: "8px 12px",
}));

const TableWrapper = styled(Box)({
  width: "100%",
});

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));
// SKU dropdown start
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 135,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

// Move this outside of AllOrderTable
function DiscountDialog({
  open,
  onClose,
  onSubmit,
  title,
  skuList,
  onSkuChange,
  selectedSku,
  // discountState
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ component: "form", onSubmit }}
    >
      <DialogTitle>{title}</DialogTitle>
      {title === "Enter Free Pkts Sku" && (
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Tooltip title="Select SKU" arrow placement="top">
            <Autocomplete
              disablePortal
              size="small"
              sx={{ width: "80%" }}
              options={skuList}
              value={selectedSku} // Bind the selected value
              onChange={onSkuChange} // Handle SKU selection
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField {...params} label="Select SKU" />
              )}
            />
          </Tooltip>
        </Grid>
      )}
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="discount"
          label="Enter"
          type="number"
          // value={discountState}
          fullWidth
          variant="standard"
          inputProps={{
            min: 0,
            max: 999,
            maxLength: 3,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
function SkuDeletePopup({
  open,
  onClose,
  onSubmit,
  title,
  skuList,
  onSkuChange,
  SkuOptions, // Array of { itemId, sku_qty }
  selectedSku,
  orderId, // Required for the payload
}) {
  // Filter out items with null itemId
  const skuItems = SkuOptions
    ? SkuOptions.filter((sku) => sku.itemId !== null)
    : [];

  // State to track selected SKUs for deletion
  const [selectedSkus, setSelectedSkus] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (itemId) => {
    setSelectedSkus(
      (prev) =>
        prev.includes(itemId)
          ? prev.filter((id) => id !== itemId) // Deselect
          : [...prev, itemId] // Select
    );
  };

  // Handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Create payload for free_pkts_sku
    const freePktsSku = selectedSkus.map((itemId) => ({
      itemId: itemId,
    }));

    if (freePktsSku.length > 0) {
      onSubmit(freePktsSku); // Pass the payload to the submit handler
    } else {
      console.warn("No SKUs selected for deletion");
    }

    onClose(); // Close the popup after submission
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ component: "form", onSubmit: handleFormSubmit }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {skuItems.length > 0 ? (
          <FormGroup>
            {skuItems.map((sku, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedSkus.includes(sku.itemId)}
                    onChange={() => handleCheckboxChange(sku.itemId)}
                  />
                }
                label={`SKU: ${sku.itemId} (Qty: ${sku.sku_qty})`}
              />
            ))}
          </FormGroup>
        ) : (
          <Typography>No SKU options available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" disabled={selectedSkus.length === 0}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// Reusable Button Component
const CustomButton = ({ disabled, onClick, children }) => (
  <Button
    disabled={disabled}
    variant="outlined"
    onClick={onClick}
    sx={{
      border: "1px solid #5052ba",
      color: "#5052ba",
      fontSize: { xs: "0.6rem", sm: "0.65rem" },
      px: { xs: 1, sm: 1 },
    }}
  >
    <Typography component="span">{children}</Typography>
  </Button>
);

// Reusable button with tooltip
const ButtonWithTooltip = ({ disabled, onClick, title }) => (
  <Tooltip
    title={disabled ? "To change, contact admin" : null}
    disableHoverListener={!disabled}
    componentsProps={{
      tooltip: {
        sx: {
          fontSize: "1rem",
          padding: "10px",
          maxWidth: "250px",
          backgroundColor: "#333",
          color: "#fff",
        },
      },
    }}
  >
    <span style={{ display: "inline-block" }}>
      <CustomButton disabled={disabled} onClick={onClick}>
        {title}
      </CustomButton>
    </span>
  </Tooltip>
);

export default function AllOrderTable({ SettotalRowCount2 }) {
  const currentDate = new Date().toISOString().split("T")[0];
  const [dateFilter, setDateFilter] = useState(currentDate);
  const [rows, setRows] = useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openViewDialogImg, setOpenViewDialogImg] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewData, setViewData] = useState([]);
  const [viewData_PDF, setViewData_PDF] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermValue, setSearchTermValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null); // New state for debounce timer
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState("");
  // const discountInputRef = useRef(null); // Ref for the TextField
  let orderId = 0;
  const userRole = localStorage.getItem("user");
  const [openFreePkts, setOpenFreePkts] = useState(false);
  const [openCashDiscount, setOpenCashDiscount] = useState(false);
  const [openAddDiscount, setOpenAddDiscount] = useState(false);
  const [Discount_free_pkts, setDiscount_free_pkts] = useState([]);
  const [discount_CD, setDiscount_CD] = useState("");
  const [discount, setDiscount] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [skuList, setSkuList] = useState([]);
  const [selectedSku, setSelectedSku] = useState(null); // Track selected SKU

  const [openSkuDeletePopup, setOpenSkuDeletePopup] = useState(false);

  const headers = [
    { label: "OrderID", show: true },
    { label: "OrderBy", show: true },
    { label: "PayerId", show: true },
    { label: "Stockist", show: true },
    { label: "Beat Name", show: true },
    { label: "Customer", show: true },
    { label: "Group", show: true },
    { label: "Date & Time", show: true },
    { label: "Order_Status", show: true },
    { label: "Quantity", show: true },
    { label: "Location", show: true },
    { label: "Image", show: true },
    { label: "Action", show: true },
    { label: "Delete", show: localStorage.getItem("user") === "admin" },
  ];

  const CustomTableCell = ({ children, align = "center", style, colSpan }) => (
    <TableCell
      align={align}
      sx={{ padding: "2px 2px" }}
      style={style}
      colSpan={colSpan}
    >
      {children}
    </TableCell>
  );

  const DiscountRow = ({ label, value, color, onDelete }) => (
    <StyledTableRow
      style={{
        backgroundColor: label !== "FREE PKTS" ? "#65977b7a" : "#f5f5f5",
      }}
    >
      <CustomTableCell colSpan={2} style={{ fontWeight: "bold", color }}>
        {label}
      </CustomTableCell>
      <CustomTableCell style={{ fontWeight: "bold", color }}>:</CustomTableCell>
      <CustomTableCell />
      <CustomTableCell />
      <CustomTableCell
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          color: "#5d7172",
        }}
      >
        <span style={{ marginRight: "8px" }}>{value || 0}</span>
        {!(label === "TOTAL AFTER DISCOUNT") && userRole === "admin" && (
          <DeleteForeverOutlined
            sx={{
              color: "#ff4d4d",
              cursor: "pointer",
              "&:hover": { color: "#d63031" },
            }}
            onClick={onDelete}
          />
        )}
      </CustomTableCell>
      <CustomTableCell />
      <CustomTableCell />
    </StyledTableRow>
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  // Handle opening and closing dialogs
  const handleOpen = (setter) => () => setter(true);
  const handleClose = (setter) => () => setter(false);

  // Handle form submission
  const handleSubmit = (setter, type) => (event) => {
    event.preventDefault();
    const discountValue = event.target.discount.value;
    if (type === "free_pkts_sku") {
      // Construct payload for "Free Pkts"
      const payload = {
        sku_qty: discountValue,
        itemId: selectedSku,
      };

      // Call API with the payload
      updateItemDiscount(type, payload);
    } else {
      if (/^\d{0,3}$/.test(discountValue)) {
        updateItemDiscount(type, discountValue);
      }
    }
    setter(false);
  };

  const dispatch = useDispatch();
  const selectedFilter = useSelector(
    (state) => state.allOrderDateFilter.selectedFilter
  );
  const customDateRange = useSelector(
    (state) => state.allOrderDateFilter.customDateRange
  );

  // total count sending to OrderDetails compo. start
  SettotalRowCount2(totalRowCount);
  // total count sending to OrderDetails compo. end

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOpenDialog(false);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rowsPerPage changes
    setOpenDialog(false);
  };

  const getOrderList = useCallback(async () => {
    setIsLoading(true);
    const params = new URLSearchParams({
      page: page + 1,
      per_page: rowsPerPage,
      start_date: customDateRange.start_date,
      end_date: customDateRange.end_date,
      ...(localStorage.getItem("user") !== "admin"
        ? { user: localStorage.getItem("user") }
        : {}),
      ...(searchTermValue ? { query_string: searchTermValue } : {}),
    });

    try {
      const url = API_ENDPOINTS.Api_order_taking;
      const result = await getRequestInstanceOne(url, params);
      const data = result.data[0];

      const restructureData = data.results?.map((items) => ({
        id: items?.id,
        items: items?.items,
        orderId: items?.orderId,
        order_by: items?.order_by?.name,
        payerId: items?.r_info?.payerId,
        s_name: items?.r_info?.s_name,
        customer: items?.r_info?.r_name,
        area: items?.r_info?.area,
        group: items?.r_info?.salesgroup,
        order_at: items?.order_at,
        status: items?.status,
        qty: items?.items
          ?.reduce((total, item) => total + Number(item?.qty), 0)
          .toString(),
        location: items?.location,
        image: items?.image,
      }));

      setRows(restructureData);
      setTotalRowCount(
        searchTermValue
          ? result.data[0]?.count[0].total
          : result.data[0]?.count[0].total
      );
    } catch (error) {
      console.error(error);
      setRows([]);
    } finally {
      setIsLoading(false);
    }
  }, [
    dateFilter,
    page,
    rowsPerPage,
    customDateRange,
    searchTermValue,
    selectedFilter,
  ]);

  const handleCustomRangeSubmit = () => {
    const { start_date, end_date } = customDateRange;
    dispatch(updateSelectedFilterAllOrder(`${start_date} to ${end_date}`));
    setOpenDialog(false);
  };

  const handleFilterSelect = (filter) => {
    dispatch(updateSelectedFilterAllOrder(filter));
    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Custom Range":
        setOpenDialog(true);
        return;
      default:
    }
    dispatch(updateCustomDateRangeAllOrder({ start_date, end_date }));
  };

  useEffect(() => {
    getOrderList(); // Ensure it calls the updated customDateRange values
  }, [page, rowsPerPage, searchTermValue, selectedFilter]);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);

    // Clear previous debounce timer
    // if (debounceTimer) clearTimeout(debounceTimer);

    // Set new debounce timer
    const newTimer = setTimeout(() => {
      if (event.target.value.trim() !== "") {
        // setSearchTermValue(event.target.value); // Trigger search when user stops typing
        // setPage(0);
      } else {
        setSearchTermValue(""); // Trigger clear when input is empty
        setPage(0);
      }
    }, 2000); // 2-second delay

    setDebounceTimer(newTimer); // Save the timer to the state
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    dispatch(updateSelectedFilterAllOrder(""));
  };

  const handleDateChange = (field) => (event) => {
    dispatch(
      updateCustomDateRangeAllOrder({
        ...customDateRange,
        [field]: event.target.value,
      })
    );
  };

  const handleSearchClick = () => {
    setSearchTermValue(searchTerm);
    setPage(0);
  };

  const handleClearSearch = () => {
    setSearchTermValue("");
    setSearchTerm("");
    setPage(0);
    // getOrderList();
  };

  // Making text ellipsis... start
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Making text ellipsis... end
  const handleopenView_img1 = (params) => {
    setImage(params.image);
    setOpenViewDialogImg(true);
  };

  const getOrderListViewItem1 = async (orderid) => {
    setIsLoading(true);
    try {
      const url = API_ENDPOINTS.Api_all_order_view;
      const params = {
        orderId: orderid,
        start_date: customDateRange.start_date,
        end_date: customDateRange.end_date,
      };
      const result = await getRequestInstanceOne(url, params);
      const allOrderViewData = result.data;

      const orderData = allOrderViewData[0];

      // Update discount-related states
      setDiscount(orderData.discount || 0);
      setDiscount_CD(orderData.discount_cd || 0);

      const freePktsSku = orderData.free_pkts_sku || [];

      const aggregatedFreePkts = freePktsSku.map((item) => ({
        itemId: item.itemId,
        sku_qty: parseInt(item.sku_qty, 10), // Ensure it's a number
      }));

      setDiscount_free_pkts(aggregatedFreePkts);

      if (allOrderViewData && allOrderViewData.length > 0) {
        // Update view data and dialog state
        setViewData(allOrderViewData);
        setOpenViewDialog(true);
      } else {
        setViewData([]);
        setOpenViewDialog(true);
      }
    } catch (error) {
      console.error(error);
      setViewData([]);
    } finally {
      setIsLoading(false);
    }
  };
  // Get api for PDF
  const fetchAllOrderView = async (orderid) => {
    const params = new URLSearchParams({
      orderId: orderid,
      invoice_number: orderid,
    });

    const url = API_ENDPOINTS.Api_all_order_view;

    try {
      const response = await getRequestInstanceOne(url, params);

      if (!response.ok) {
        console.warn(
          `Warning: API responded with status ${response.status} ${response.statusText}`
        );
      }

      const data = response;

      // Handle your data (e.g., set it in state)
      setViewData_PDF(data); // Uncomment if you have a state setter
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  // Post api for PDF
  const fetchInvoiceGenerator = async (orderId) => {
    try {
      // Define API endpoint
      const endpoint = API_ENDPOINTS.Invoice_generator;
      const body = { orderId };
      // Use reusable postRequest function
      const result = await postRequest(endpoint, body);

      // Fetch all orders after invoice generation
      fetchAllOrderView(orderId);

      return result;
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  const fetchSkuIds = async () => {
    setIsLoading(true);

    try {
      const url = API_ENDPOINTS.skulist;
      const response = await getRequestInstanceOne(url);

      // Extract skuId correctly
      const skuList = response.data;

      // console.log("skuList", skuList);
      setSkuList(skuList); // Uncomment to store in state if needed
    } catch (error) {
      console.error("Error occurred during Sku list fetch", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSkuChange = (event, value) => {
    setSelectedSku(value); // Update selected SKU
  };

  const handleopenView1 = (params) => {
    // console.log("before clicked", params);
    const orderid = params?.orderId;
    const cash_disc = params?.cash_disc;
    if (orderid) {
      getOrderListViewItem1(orderid);
      setTimeout(() => {
        fetchInvoiceGenerator(orderid);
      }, 2000);
    }
  };

  // PDF Generator
  // const PDF_Generator = () => {
  //   if (orderId) {
  //     fetchInvoiceGenerator(orderId);
  //   }
  // };

  let totalQty = 0;
  let totalAmount = 0;
  // let totalTPR = 0;
  let totalTax = 0;
  let totalFreePkts = 0;
  let totalCD = 0;
  let totalPrice = 0;
  let totalrateIncTax = 0;

  // Calculate totals
  if (viewData?.length > 0) {
    viewData.forEach((view) => {
      view.itemid.forEach((_, idx) => {
        const qty = parseFloat(view?.quantity[idx]) || 0;
        const price = parseFloat(view?.price[idx]) || 0;
        const total = qty * price;
        // const tpr = parseFloat(view?.discount[idx]) || 0;
        const tax = parseFloat(view?.tax[idx]) || 0;
        const freePkts = parseFloat(view?.free[idx]) || 0;
        const cd = parseFloat(view?.cash_disc[idx]) || 0;

        totalQty += qty;
        totalAmount += total;
        // totalTPR += tpr;
        totalTax += tax;
        totalFreePkts += freePkts;
        totalCD += cd;
        totalPrice += price;
        totalrateIncTax += price * (1 + tax / 100);
      });
    });
  }

  // function for delete data
  const DeleteData = async (row) => {
    const { orderId } = row;
    const data = false;

    if (!window.confirm("Are you sure you want to delete this item?")) {
      toast.error("Delete cancelled.");
      return;
    }

    setIsLoading(true);

    try {
      const endpoint = API_ENDPOINTS.Delete_orders;
      const body = { orderId, data };

      // console.log("Request body:", body);

      await postRequest(endpoint, body);

      getOrderList(customDateRange.start_date, customDateRange.end_date);
      toast.success("Successfully Deleted!");
    } catch (error) {
      console.error(
        "Failed to delete data:",
        error.response?.data || error.message
      );
      toast.error("Failed to delete the data");
    } finally {
      setIsLoading(false);
    }
  };

  // Calling Put APi for discount
  const updateItemDiscount = async (type, value) => {
    const endpoint = API_ENDPOINTS.Edit_orders;

    try {
      let body;
      if (type === "free_pkts_sku") {
        // Handle "Free Pkts" payload
        const valueString = JSON.stringify(value); // For checking sku_qty
        body = {
          orderId: orderId,
          free_pkts_sku: value, // Keep as array, not stringified here
        };
        // Check if value contains "sku_qty"
        if (!valueString.includes('"sku_qty"')) {
          body.remove = true; // Add as boolean if sku_qty is not present
        }
      } else {
        body = {
          orderId: orderId,
          [type]: value || "0",
        };
      }

      // Make the API request
      const response = await putRequest(endpoint, body);

      // Check for non-OK response
      if (!response || !response.ok) {
        throw new Error(
          `HTTP error! Status: ${response ? response.status : "undefined"}`
        );
      }
    } catch (error) {
      // Enhanced error logging
      console.error(
        "Error updating discount:",
        error.response?.data || error.message
      );
    } finally {
      // Refresh the order list view
      await getOrderListViewItem1(orderId);
    }
  };

  const calculateDiscountedAmount = () => {
    if (!(discount || discount_CD)) return totalAmount.toFixed(2);
    return Number(
      Number(totalAmount.toFixed(2) || 0) -
        Number(discount || 0) +
        Number(-discount_CD || 0)
    ).toFixed(2);
  };

  // Delete Discount handler
  const handleDeleteDiscount = (type) => {
    if (type === "free_pkts_sku") {
      setOpenSkuDeletePopup(true); // Open the SkuDeletePopup
    } else {
      const confirmed = window.confirm(
        "Are you sure you want to delete this Discount?"
      );

      if (confirmed) {
        updateItemDiscount(type, 0); // Directly delete for other types
      }
    }
  };

  return (
    <>
      <Toaster />
      <Box sx={{ width: "100%" }}>
        <CustomLoader open={isLoading} />
        <Paper sx={{ width: "100%", p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center">
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", sm: "flex-start" },
                }}
              >
                <Tooltip
                  title={`${customDateRange.start_date} ${customDateRange.end_date}`}
                  placement="top"
                  arrow
                >
                  <Autocomplete
                    disablePortal
                    disableClearable
                    size="small"
                    sx={{ bgcolor: "#fff", width: { xs: "100%", sm: 200 } }}
                    id="combo-box-demo"
                    value={selectedFilter}
                    options={[
                      "Today",
                      "Weekly",
                      "Monthly",
                      "Yearly",
                      "Custom Range",
                    ]}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => handleFilterSelect(value)}
                    renderInput={(params) => (
                      <TextField {...params} label="Date Filter" />
                    )}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={4} md={5}>
                <TextField
                  size="small"
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearchClick}>
                          <SearchIcon />
                        </IconButton>

                        <IconButton onClick={handleClearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <TableWrapper>
            <TableContainerWrapper component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {headers
                      .filter((header) => header.show) // Show only relevant headers
                      .map((header) => (
                        <StyledTableCell
                          key={header.label}
                          isHeader
                          sx={{ padding: "5px 5px", whiteSpace: "nowrap" }}
                        >
                          {header.label}
                        </StyledTableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <Tooltip title={row.orderId || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.orderId || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.order_by || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.order_by || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                        }}
                      >
                        {row.payerId || "-"}
                      </TableCell>
                      <Tooltip title={row.s_name || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.s_name || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.location.beat_name || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.location.beat_name || "-", 15)}
                        </TableCell>
                      </Tooltip>
                      <Tooltip
                        title={row.customer || "-"}
                        placement="top"
                        arrow
                      >
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.customer || "-", 15)}
                        </TableCell>
                      </Tooltip>

                      {/* <Tooltip title={row.area || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.area || "-", 15)}
                        </TableCell>
                      </Tooltip> */}

                      <Tooltip title={row.group || "-"} placement="top" arrow>
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "150px", // You can adjust the width based on your layout
                          }}
                        >
                          {truncateText(row.group || "-", 15)}
                        </TableCell>
                      </Tooltip>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.order_at || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {/* {row.location.no_order_reason || "-"} */}

                        {row.qty
                          ? row.qty == 0
                            ? row.location.no_order_reason
                            : row.status
                          : "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.qty || "-"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                        }}
                      >
                        {row.location &&
                        row.location.lattitude &&
                        row.location.logitude ? (
                          <a
                            href={`https://www.google.com/maps?q=${row.location.logitude},${row.location.lattitude}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton>
                              <LocationOnIcon />
                            </IconButton>
                          </a>
                        ) : (
                          "-"
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          padding: "0 5px",
                          textAlign: "center",
                          cursor: "not-allowed",
                        }}
                      >
                        <IconButton
                          disabled={
                            row.location.no_order_reason == "Store Closed"
                              ? false
                              : true
                          }
                        >
                          <ImageIcon
                            onClick={() => handleopenView_img1(row)}
                            sx={{
                              color:
                                row.location.no_order_reason == "Store Closed"
                                  ? green["600"]
                                  : green["100"],
                            }}
                          />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          background: "white",
                          padding: "0 5px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="inherit"
                          onClick={() => handleopenView1(row)}
                          disabled={row.qty == 0 ? true : false}
                        >
                          View
                        </Button>
                      </TableCell>

                      {localStorage.getItem("user")?.trim() === "admin" && (
                        <TableCell
                          align="left"
                          sx={{
                            padding: "0 5px",
                            textAlign: "center",
                          }}
                        >
                          <IconButton>
                            <DeleteForeverOutlined
                              sx={{ color: "red" }}
                              onClick={() => DeleteData(row)}
                            />
                          </IconButton>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainerWrapper>
          </TableWrapper>
          <TablePagination
            component="div"
            count={totalRowCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <CustomDialog
        open={openDialog}
        onClose={handleDialogClose}
        title="Select Date Range"
        fullscreen={false}
        maxWidth="sm"
      >
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={customDateRange.start_date}
          onChange={handleDateChange("start_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={customDateRange.end_date}
          onChange={handleDateChange("end_date")}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDialogClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCustomRangeSubmit}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>

      <CustomDialog
        open={openViewDialog}
        onClose={() => setOpenViewDialog(false)}
        title={`View Order`}
        PDFData={`PDF`}
        ExcelData={`Excel`}
        fullscreen={false}
        maxWidth="md"
        MyBgColor="#727297"
        ViewDATA={viewData_PDF}
        Discounted_Amount={discount_CD}
        free_pkts_sku={Discount_free_pkts}
        company_scheme_disc={discount}
        // PDF_GeneraTor={PDF_Generator}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: { xs: "95%", sm: "80%", md: "md" },
          },
        }}
      >
        {viewData.map((val) => {
          orderId = val?._id;
          return (
            <>
              <Box sx={{ flexGrow: 1, overflowX: "auto", mb: 2 }}>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent="space-between"
                >
                  <Grid item xs={12} sm={4}>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                        color: "maroon",
                      }}
                    >
                      OrderId:
                      <small style={{ color: "darkgreen" }}>{val?._id}</small>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                        color: "maroon",
                      }}
                    >
                      Date:
                      <small style={{ color: "darkgreen" }}>
                        {val?.datetime}
                      </small>
                    </Typography>
                  </Grid>

                  <Box
                    container
                    mt={2}
                    display="flex"
                    justifyContent="flex-end"
                    gap={1}
                    sx={{
                      px: { xs: 1, sm: 2 },
                    }}
                  >
                    {/* Free Pkts Button */}
                    <ButtonWithTooltip
                      title="Free Pkts"
                      disabled={
                        userRole !== "admin" &&
                        Number(Discount_free_pkts.sku_qty) !== 0
                      }
                      onClick={async () => {
                        setOpenFreePkts(true);
                        await fetchSkuIds();
                      }}
                    />

                    <DiscountDialog
                      open={openFreePkts}
                      onClose={handleClose(setOpenFreePkts)}
                      onSubmit={handleSubmit(setOpenFreePkts, "free_pkts_sku")}
                      title="Enter Free Pkts Sku"
                      skuList={skuList}
                      onSkuChange={handleSkuChange}
                      selectedSku={selectedSku}
                      discountState={Discount_free_pkts}
                    />

                    {/* CD Button */}
                    <ButtonWithTooltip
                      title="CD"
                      disabled={
                        userRole !== "admin" && Number(discount_CD) !== 0
                      }
                      onClick={handleOpen(setOpenCashDiscount)}
                    />

                    <DiscountDialog
                      open={openCashDiscount}
                      onClose={handleClose(setOpenCashDiscount)}
                      onSubmit={handleSubmit(
                        setOpenCashDiscount,
                        "discount_cd"
                      )}
                      title="Enter CD"
                      discountState={discount_CD}
                    />

                    {/* Company Scheme Discount Button */}
                    <ButtonWithTooltip
                      title="Company Scheme Discount"
                      disabled={userRole !== "admin" && Number(discount) !== 0}
                      onClick={handleOpen(setOpenAddDiscount)}
                    />

                    <DiscountDialog
                      open={openAddDiscount}
                      onClose={handleClose(setOpenAddDiscount)}
                      onSubmit={handleSubmit(setOpenAddDiscount, "discount")}
                      title="Enter Company Scheme Discount"
                      discountState={discount}
                    />
                  </Box>
                </Grid>
              </Box>
            </>
          );
        })}
        <Box sx={{ overflowX: "auto", mt: 2 }}>
          <TableWrapper>
            <TableContainerWrapper component={Paper} sx={{ width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {[
                      "SKU",
                      "MRP",
                      "Rate",
                      "Rate (Incl. of Tax)",
                      "Qty",
                      "Total",
                      "Tax",
                      "CD",
                    ].map((header) => (
                      <StyledTableCell
                        key={header}
                        isHeader
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          backgroundColor: "#f5f5f5",
                          color: "#835454",
                          fontSize: {
                            xs: "0.7rem",
                            sm: "0.8rem",
                            md: "0.9rem",
                            padding: "2px 2px",
                          },
                          whiteSpace: "nowrap",
                        }}
                      >
                        {header}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewData?.length > 0
                    ? viewData.map((view, index) =>
                        view.itemid.map((_, idx) => {
                          const qty =
                            parseFloat(view?.quantity[idx])?.toFixed(2) || 0;
                          const price =
                            parseFloat(view?.price[idx])?.toFixed(2) || 0;
                          const total = (qty * price).toFixed(2);
                          // const tpr =
                          //   parseFloat(view?.discount[idx])?.toFixed(2) || 0;
                          const tax = parseFloat(view?.tax[idx]) || 0;
                          const freePkts =
                            parseFloat(view?.free[idx])?.toFixed(2) || 0;
                          const cd =
                            parseFloat(view?.cash_disc[idx])?.toFixed(2) || 0;
                          const rateIncTax = (price * (1 + tax / 100)).toFixed(
                            2
                          );
                          return (
                            <StyledTableRow key={`${index}-${idx}`}>
                              {[
                                view?.itemid[idx],
                                "--",
                                price,
                                rateIncTax,
                                qty,
                                total,
                                tax + "%",
                                cd,
                              ].map((field) => (
                                <TableCell
                                  align="center"
                                  sx={{ padding: "2px 2px" }}
                                >
                                  {field || "--"}
                                </TableCell>
                              ))}
                            </StyledTableRow>
                          );
                        })
                      )
                    : null}

                  {/* Summary Row */}
                  <StyledTableRow
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                    style={{
                      backgroundColor: "#e0e0e0",
                    }}
                  >
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      Total
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      ---
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      {totalPrice.toFixed(2)}
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      {totalrateIncTax.toFixed(2)}
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      {totalQty.toFixed(2)}
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      {totalAmount.toFixed(2)}
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      {/* {totalTax.toFixed(2)} */}
                    </CustomTableCell>
                    <CustomTableCell
                      style={{ fontWeight: "bold", color: "#936843" }}
                    >
                      {totalCD.toFixed(2)}
                    </CustomTableCell>
                  </StyledTableRow>

                  <DiscountRow
                    label="FREE PKTS"
                    value={
                      Discount_free_pkts.reduce(
                        (sum, item) => sum + (parseInt(item.sku_qty, 10) || 0),
                        0
                      ) || 0
                    }
                    color="#4949B3"
                    onDelete={() => handleDeleteDiscount("free_pkts_sku")}
                  />

                  {/* Add SkuDeletePopup here */}
                  <SkuDeletePopup
                    open={openSkuDeletePopup}
                    onClose={() => setOpenSkuDeletePopup(false)}
                    onSubmit={(payload) =>
                      updateItemDiscount("free_pkts_sku", payload)
                    }
                    SkuOptions={Discount_free_pkts}
                    title="Confirm Delete Free Pkts"
                    skuList={skuList}
                    onSkuChange={handleSkuChange}
                    selectedSku={selectedSku}
                    orderId={orderId}
                  />

                  <DiscountRow
                    label="CS DISCOUNT"
                    value={discount}
                    color="#4949B3"
                    onDelete={() => handleDeleteDiscount("discount")}
                  />

                  <DiscountRow
                    label="CD"
                    value={discount_CD}
                    color="#4949B3"
                    onDelete={() => handleDeleteDiscount("discount_cd")}
                  />

                  <DiscountRow
                    label="TOTAL AFTER DISCOUNT"
                    value={calculateDiscountedAmount()}
                    color="#4949B3"
                  />
                </TableBody>
              </Table>
            </TableContainerWrapper>
          </TableWrapper>
        </Box>
      </CustomDialog>

      {/* custome dialog start*/}
      <CustomDialog
        open={openViewDialogImg}
        onClose={() => {
          setOpenViewDialogImg(false);
        }}
        title={`View Image`}
        fullscreen={false} // Set to true if you want the dialog to be fullscreen
        maxWidth="md" // Set the maxWidth as needed
      >
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <CustomLoader open={isLoading} />

          {/* Conditional rendering based on image availability */}
          {image ? (
            <img src={image} alt="image" width={600} height={400} />
          ) : (
            <>
              <h2 style={{ color: "#ff0000a1" }}>No image found</h2>
            </>
          )}
        </div>
      </CustomDialog>
    </>
  );
}
