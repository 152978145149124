import {
  axiosInstance1,
  axiosInstance2,
  axiosInstance3,
  axiosInstance4,
} from "./axiosInstance";

const handleRequest = async (instance, method, endpoint, options = {}) => {
  try {
    const response = await instance[method](endpoint, options);
    return response.data;
  } catch (error) {
    console.error(`Error during ${method.toUpperCase()} request:`, error);
    throw error;
  }
};

export const getRequest = (instance, endpoint, params) =>
  handleRequest(instance, "get", endpoint, { params });

export const getRequestForDownload = (endpoint) =>
  handleRequest(axiosInstance1, "get", endpoint, { responseType: "blob" });

export const postRequest = (endpoint, body) =>
  handleRequest(axiosInstance1, "post", endpoint, body);

export const putRequest = (endpoint, body) =>
  handleRequest(axiosInstance1, "put", endpoint, body);

// Usage Examples
export const getRequestInstanceOne = (endpoint, params) =>
  getRequest(axiosInstance1, endpoint, params);
export const getRequestInstanceTwo = (endpoint, params) =>
  getRequest(axiosInstance2, endpoint, params);
export const getRequestInstanceThree = (endpoint, params) =>
  getRequest(axiosInstance3, endpoint, params);
export const getRequestInstanceFour = (endpoint, params) =>
  getRequest(axiosInstance4, endpoint, params);
