import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Grid,
  TablePagination,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteForeverOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import * as XLSX from "xlsx";
import { CustomLoader } from "../../components/CustomLoader/CustomLoader";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import {
  getRequestInstanceFour,
  postRequest,
  putRequest,
} from "../../apiServices/apiCall";
// import { postRequest } from "../../apiServices/apiCall";
import CircularProgress from "@mui/material/CircularProgress";
import API_ENDPOINTS from "../../apiServices/apiEndpoints";

// Spinner styles for the TextField
const spinnerStyles = {
  position: "absolute",
  right: "10px",
  top: "25%",
  transform: "translateY(-50%)",
  pointerEvents: "none",
};

// Styled components for better font and table styling
const StyledTableCell = styled(TableCell)(({ theme, isHeader, isFooter }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : isFooter ? "#f5f5f5" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: "center",
  position: isHeader || isFooter ? "sticky" : "relative",
  top: isHeader ? 0 : "auto",
  bottom: isFooter ? 0 : "auto",
  zIndex: isHeader || isFooter ? 1 : "auto",
  fontWeight: isHeader ? 700 : 400, // Bolder header font
  fontFamily: "Roboto, sans-serif", // Modern font family
  fontSize: isHeader ? "16px" : "14px", // Larger header font
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  "&:hover": {
    backgroundColor: "#c0ebd5", // Add hover effect
  },
  fontFamily: "Roboto, sans-serif", // Modern font family
  fontSize: "14px",
  padding: "8px 12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 10px",
  },
}));

// Styling for the image viewer
const StyledImage = styled("img")({
  maxWidthwidth: "100%",
  borderRadius: "10px", // Rounded corners
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
  border: "1px solid #ccc", // Light border
});
const TableWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  overflowX: "auto",
});

const headerCellStyles = {
  fontFamily: "'Roboto', sans-serif", // Modern, clean font
  fontWeight: "bold", // Make the header bold
  fontSize: "12.5px", // Set a larger font size for readability
  color: "#fff", // Primary color for the header text
  letterSpacing: "0.05em", // Slight letter spacing for a clean look
  textTransform: "uppercase", // Uppercase text for emphasis
  //   borderBottom: "2px solid #3032a3", // Custom border for the header
  padding: "8px 8px", // Adjust padding for better alignment
  backgroundColor: "#000000", // Optional: light background for header
};

const searchStyles = {
  "& .MuiOutlinedInput-root fieldset": { borderColor: "#000000" },
  "&:hover .MuiOutlinedInput-root fieldset": { borderColor: "#4042a3" },
  "&.Mui-focused .MuiOutlinedInput-root fieldset": { borderColor: "#3032a3" },
};

// EditDialog Component (Capitalized as a React component)
function EditDialog({ open, onClose, onSubmit, title, initialRemark }) {
  const [remark, setRemark] = useState(initialRemark || "");

  const handleInputChange = (event) => {
    setRemark(event.target.value);
  };

  useEffect(() => {
    if (open) {
      setRemark(initialRemark || "");
    }
  }, [open, initialRemark]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ component: "form", onSubmit: (e) => onSubmit(e, remark) }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.11)",
          },
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          value={remark}
          margin="dense"
          name="remark"
          label="Edit Remark"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

// Define a reusable Autocomplete component
const ReusableAutocomplete = ({
  label,
  options,
  value,
  setValue,
  disabled = false,
  fetchFunction = null,
  dependency = null,
  tooltipText = "",
}) => {
  const handleChange = useCallback(
    (event, value) => {
      setValue(value);
      if (fetchFunction && dependency) {
        fetchFunction(dependency, value);
      } else if (fetchFunction) {
        fetchFunction(value);
      }
    },
    [setValue, fetchFunction, dependency]
  );

  return (
    <Grid
      item
      sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
    >
      <Tooltip title={disabled ? tooltipText : ""} arrow placement="top">
        <Autocomplete
          disablePortal
          disabled={disabled}
          // disableClearable
          size="small"
          sx={{ width: 220 }}
          id={`combo-box-demo-${label}`}
          value={value || null}
          options={options}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      </Tooltip>
    </Grid>
  );
};

// PropTypes for validating component props
ReusableAutocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fetchFunction: PropTypes.func,
  dependency: PropTypes.any,
};

const renderDateField = (label, value, onChange) => (
  <TextField
    label={label}
    type="date"
    value={value || ""}
    onChange={onChange}
    InputLabelProps={{ shrink: true }}
    sx={{
      height: 42,
      "& .MuiInputBase-root": { height: 42, padding: "0 40px" },
      "& .MuiOutlinedInput-root fieldset": { borderColor: "#5051ba" },
      "&:hover .MuiOutlinedInput-root fieldset": { borderColor: "#4042a3" },
      "&.Mui-focused .MuiOutlinedInput-root fieldset": {
        borderColor: "#3032a3",
      },
      mt: 2,
      ml: 3,
    }}
  />
);

const Compify = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [offerIds, setOfferIds] = useState([]);
  const [salesGroups, setSalesGroups] = useState([]);
  const [payerIds, setPayerIds] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState("");
  const [selectedSalesGroup, setSelectedSalesGroup] = useState("");
  const [selectedPayerId, setSelectedPayerId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [filter, setFilter] = useState("Today");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Total_quantity, setTotal_quantity] = useState(0);
  const [customDateRange, setCustomDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPlaceholder, setCurrentPlaceholder] = useState("");
  const [open, setOpen] = useState(false);
  const [updatedRemark, setUpdatedRemark] = useState();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [totalCount, setTotalCount] = useState(0);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  let Id = useRef("");
  let PayerId = useRef("");

  // // Initial fetch for Offer IDs based on start and end dates
  // useEffect(() => {
  //   fetchOfferIds();
  // }, [startDate, endDate]);

  // multi placeholder show
  const placeholders = [
    "by Employee ID...",
    "by Salesgroup...",
    "by Payer ID...",
    "by Name...",
    "by Type...",
    "by Sub type...",
    "by Retailer Name...",
    "by Payer Name...",
  ];

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setCurrentPlaceholder(placeholders[index]);
      index = (index + 1) % placeholders.length; // Cycle through the array
    }, 2000); // Change placeholder every 2 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  // Debounce search term
  useEffect(() => {
    const handler = setTimeout(() => {
      // Filter giftData based on the search term
      const trimmedSearchTerm = searchTerm.trim().toLowerCase();
      setDebouncedSearchTerm(trimmedSearchTerm); // Update debounced search term
      setCurrentPage(0); // Reset to first page for new search
    }, 2000); // 2000ms debounce delay

    return () => clearTimeout(handler);
  }, [searchTerm]);

  // const fetchOfferIds = async () => {
  //   setTableData([]);
  //   setIsLoading(true);
  //   // const url = `/api/gift_api_all?start_date=${startDate}&end_date=${endDate}`;
  //   const url = API_ENDPOINTS.Gift_api_all; // Endpoint URL
  //   const params = {
  //     start_date: startDate,
  //     end_date: endDate,
  //     ...(localStorage.getItem("user") !== "admin"
  //       ? { user: localStorage.getItem("user") }
  //       : {}),
  //   };
  //   try {
  //     const response = await getRequestInstanceOne(url, params);
  //     setOfferIds(response.data.map((item) => item.offerid[0]));
  //     setSalesGroups([]);
  //     setPayerIds([]);
  //     setSelectedOfferId("");
  //     setSelectedSalesGroup("");
  //     setSelectedPayerId("");
  //   } catch (error) {
  //     console.error("Error fetching offer IDs:", error);
  //   }
  //   setIsLoading(false);
  // };

  // const fetchSalesGroups = async (offerId) => {
  //   setIsLoading(true);
  //   // const url = `/api/gift_api_all?start_date=${startDate}&end_date=${endDate}&offerId=${offerId}`;
  //   const url = API_ENDPOINTS.Gift_api_all; // Endpoint URL
  //   const params = {
  //     start_date: startDate,
  //     end_date: endDate,
  //     offerId: offerId,
  //     ...(localStorage.getItem("user") !== "admin"
  //       ? { user: localStorage.getItem("user") }
  //       : {}),
  //   };

  //   try {
  //     const response = await getRequestInstanceOne(url, params);
  //     setSalesGroups(response.data[0]?.salesgroup || []);
  //     setPayerIds([]);
  //     setSelectedSalesGroup("");
  //     setSelectedPayerId("");
  //   } catch (error) {
  //     console.error("Error fetching sales groups:", error);
  //   }
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (selectedSalesGroup) {
  //     setSelectedPayerId(null); // Clear Payer ID when Sales Group is selected
  //   }
  // }, [selectedSalesGroup]);

  // useEffect(() => {
  //   if (selectedPayerId) {
  //     setSelectedSalesGroup(null); // Clear Sales Group when Payer ID is selected
  //   }
  // }, [selectedPayerId]);

  useEffect(() => {
    if (!selectedPayerId && salesGroups.length > 0 && !selectedSalesGroup) {
      setSelectedSalesGroup("All"); // Default to "All" only when neither is selected
    }
  }, [salesGroups, selectedPayerId, selectedSalesGroup]);

  useEffect(() => {
    if (!selectedSalesGroup && payerIds.length > 0 && !selectedPayerId) {
      setSelectedPayerId("All"); // Default to "All" only when neither is selected
    }
  }, [payerIds, selectedSalesGroup, selectedPayerId]);

  useEffect(() => {
    fetchPayerIds();
  }, [startDate, endDate]); // Runs when startDate or endDate changes

  const fetchPayerIds = async (selectedPayerId, selectedSalesGroup) => {
    setIsLoading(true);
    // const url = API_ENDPOINTS.Gift_api_all_payerId_compify_list; // Endpoint URL
    const url = `/get-all-list`; // Endpoint URL
    // const url = `http://192.168.0.162:5000/get-all-list`; // Endpoint URL

    const params = {
      start_date: startDate,
      end_date: endDate,
      // key: "payerid",
      // value: selectedSalesGroup,
      // key: "salesgroup",
      // value: selectedPayerId,
      salesgroup: selectedPayerId,
    };

    try {
      const response = await getRequestInstanceFour(url, params);
      setSalesGroups(
        [
          ...new Set(
            response?.body?.salesgroup
              ?.filter((id) => id) // Remove null values
              ?.map((id) => id.split(",")[0])
          ),
        ] || []
      );

      setPayerIds(
        [
          ...new Set(
            response?.body?.payer_ids?.filter((id) => id) // Remove null values
          ),
        ] || []
      );

      setSelectedPayerId("");
    } catch (error) {
      console.error("Error fetching payer IDs:", error);
    }
    setIsLoading(false);
  };

  const fetchTableData = async (payerId) => {
    setIsLoading(true);
    setIsLoadingSearch(true);

    // Prepare params object dynamically
    // const params = {
    //   page: currentPage + 1,
    //   per_page: rowsPerPage,
    //   start_date: startDate,
    //   end_date: endDate,
    //   ...(selectedOfferId && { offerId: selectedOfferId }),
    //   ...(selectedSalesGroup && { salesgroup: selectedSalesGroup }),
    //   ...(payerId && { payerId }),
    //   ...(debouncedSearchTerm && { query_string: debouncedSearchTerm }),
    //   ...(localStorage.getItem("user") !== "admin"
    //     ? { user: localStorage.getItem("user") }
    //     : {}),
    // };

    // const params = {
    //   page: currentPage + 1,
    //   per_page: rowsPerPage,
    //   start_date: startDate,
    //   end_date: endDate,
    //   ...(selectedPayerId
    //     ? { key: "payerId", value: selectedPayerId }
    //     : selectedSalesGroup
    //     ? { key: "salesgroup", value: selectedSalesGroup }
    //     : {}),
    //   ...(debouncedSearchTerm && { query_string: debouncedSearchTerm }),
    // };

    const params = {
      page: currentPage + 1,
      per_page: rowsPerPage,
      start_date: startDate,
      end_date: endDate,
      ...(selectedSalesGroup && { salesgroup: selectedSalesGroup }),
      ...(selectedPayerId && { payerid: selectedPayerId }),
      ...(debouncedSearchTerm && { query_string: debouncedSearchTerm }),
    };

    // const url = API_ENDPOINTS.Gift_api_all_payerId_compify;
    const url = `/get-compify`;
    // const url = `http://192.168.0.162:5000/get-compify`;

    try {
      const response = await getRequestInstanceFour(url, params);

      // Extract table data and total count safely
      // const tableData = response?.body?.[0]?.data || [];
      const tableData =
        response?.body?.map((entry) => ({
          emp_id: entry.emp_id,
          Count: entry.count,
          payer_id: entry.payer_id,
          salesGroup: entry.salesgroup,
          Retailer_Name: entry.retailer_name,
          Payer_Name: entry.payer_name,
          name: entry.name,
          created_date: entry.created_date,
          data: Object.entries(entry.data).flatMap(([category, items]) =>
            Object.entries(items).map(([itemName, value]) => ({
              category,
              itemName,
              value,
            }))
          ),
        })) || [];
      // const totalRecords = response?.data?.[0]?.totalCount?.[0]?.count || 0;

      const pagination = response?.pagination || {};
      // const currentPage = pagination.page || 1;
      // const perPage = pagination.per_page || 10;
      const totalRecords = pagination.total || 0;
      // const totalPages = pagination.total_pages || 1;

      setTableData(tableData);
      setTotalCount(totalRecords);
      // setRowsPerPage(perPage);
      // setCurrentPage(currentPage);
      setTotal_quantity(response?.count || 0);
    } catch (error) {
      console.error("Error fetching table data:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingSearch(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchTableData(selectedPayerId);
    }
  }, [
    selectedSalesGroup,
    selectedPayerId,
    rowsPerPage,
    currentPage,
    debouncedSearchTerm,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setCurrentPage(0);
    setRowsPerPage(10);
  }, [selectedSalesGroup, selectedPayerId]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleFilterSelect = (filter) => {
    setTotalCount(0);
    setOfferIds([]);
    setFilter(filter);

    const today = new Date();
    let start_date, end_date;

    switch (filter) {
      case "Today":
        start_date = end_date = today.toISOString().split("T")[0];
        break;
      case "Weekly":
        start_date = new Date(today.setDate(today.getDate() - 7))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Monthly":
        start_date = new Date(today.setMonth(today.getMonth() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "Yearly":
        start_date = new Date(today.setFullYear(today.getFullYear() - 1))
          .toISOString()
          .split("T")[0];
        end_date = new Date().toISOString().split("T")[0];
        break;
      case "DateRange":
        setOpenDateDialog(true); // Open dialog for custom range
        return;
      default:
        return;
    }

    setCustomDateRange({ start_date, end_date });
    setStartDate(start_date);
    setEndDate(end_date);
    setCurrentPage(0);
  };

  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  const handleViewImages = (images) => {
    setSelectedImages(images);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImages([]);
  };

  // Making text ellipsis... start
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // Export excel start

  const handleExportExcel = async () => {
    const params = {
      Selected_Salsgroup: selectedSalesGroup, // This goes in the URL path
      Selected_PayerID: selectedPayerId, // This goes in the URL path
      start_date: startDate,
      end_date: endDate,
    };
    const baseUrl = "http://64.227.130.37:8082/getAllCompifyData";
    // const baseUrl = "http://192.168.0.162:5000/getAllCompifyData";

    // Extract "value" from params separately as it's part of the URL path
    const { Selected_Salsgroup, Selected_PayerID, ...queryParams } = params;

    // Convert the remaining params into a query string
    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${baseUrl}/${Selected_Salsgroup}&${Selected_PayerID}?${queryString}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });

      if (!response.ok) throw new Error("Failed to download file");

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `compify_report_${selectedSalesGroup}_${selectedPayerId}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  // Export excel end

  // for editing
  const handleOpen = (_id, remark, payerId) => {
    Id.current = _id; // Set the ID for the selected row
    PayerId.current = payerId;
    setUpdatedRemark(remark); // Set the initial remark value in the modal
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false);
    setUpdatedRemark(""); // Clear the remark when closing
  };

  // Submit handler for dialog form for Editing remark
  const handleEditSubmit = async (event, remark) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const endpoint = API_ENDPOINTS.Gift_remark_edit;
      const body = {
        _id: Id.current,
        remark,
      };

      console.log("Request body:", body);

      // Use the postRequest utility
      const response = await postRequest(endpoint, body);

      toast.success("Remark successfully updated!");
      fetchTableData(PayerId.current); // Refresh table data
      handleClose();
    } catch (error) {
      console.error(
        "Failed to update the remark:",
        error.response?.data || error.message
      );
      toast.error("Failed to update the remark");
    } finally {
      setIsLoading(false);
    }
  };

  // post api for deleting

  const DeleteData = async (_id) => {
    setIsLoading(true);
    Id.current = _id; // Set the current value of _id in the ref
    const data = "false";

    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (!confirmed) {
      toast.error("Delete cancelled.");
      setIsLoading(false);
      return;
    }

    try {
      const endpoint = API_ENDPOINTS.Gift_remark_Delete;
      const body = {
        _id: Id.current,
        data,
      };

      console.log("Request body:", body);

      // Use the postRequest utility
      const response = await postRequest(endpoint, body);

      fetchTableData(selectedPayerId); // Refresh the data
      toast.success("Successfully Deleted!");
    } catch (error) {
      console.error(
        "Failed to delete data:",
        error.response?.data || error.message
      );
      toast.error("Failed to delete the data");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Toaster />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap" // Adjusts for responsiveness
        gap={2} // Adds spacing between components
        sx={{ padding: "16px" }} // Optional padding for layout
      >
        {/* Left Side: Reusable Components */}
        <Box display="flex" gap={2}>
          {/* Offer ID Dropdown */}
          {/* <ReusableAutocomplete
            label="Offer ID"
            options={offerIds}
            value={selectedOfferId}
            setValue={setSelectedOfferId}
            fetchFunction={fetchSalesGroups}
          /> */}

          {/* Sales Group Dropdown */}
          <ReusableAutocomplete
            label="Sales Group"
            options={salesGroups}
            value={selectedSalesGroup || ""}
            setValue={setSelectedSalesGroup}
            // disabled={!selectedOfferId}
            fetchFunction={fetchPayerIds}
            // dependency={selectedOfferId}
            // tooltipText="Select OfferID First"
          />

          {/* Payer ID Dropdown */}
          <ReusableAutocomplete
            label="Payer ID"
            options={payerIds}
            value={selectedPayerId || ""}
            setValue={setSelectedPayerId}
            disabled={!selectedSalesGroup}
            fetchFunction={fetchTableData}
            tooltipText="Select Salesgroup First"
          />
          {/* Total Count Display */}
          {/* <Box
            display="flex"
            alignItems="center"
            paddingX={2}
            bgcolor="lightgray"
            borderRadius={2}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "13px", color: "GrayText" }}
            >
              Total Qty:
              <span style={{ fontSize: "19px", color: "green" }}>
                {Total_quantity}
              </span>
            </Typography>
          </Box> */}
        </Box>

        {/* Right Side: Search, Date Filter, Export */}
        <Box display="flex" alignItems="center" gap={2}>
          {/* Search Field */}
          <Grid item>
            <TextField
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setIsLoadingSearch(true);
                setTimeout(() => {
                  setIsLoadingSearch(false);
                }, 5000);
              }}
              placeholder={`Search ${currentPlaceholder}`}
              sx={{
                width: "216px",
                ...searchStyles,
                "& .MuiInputBase-input": {
                  padding: "8px",
                  "&::placeholder": { color: "#000000", opacity: 1 },
                },
              }}
              InputProps={{
                endAdornment: isLoadingSearch ? (
                  <CircularProgress size={16} sx={spinnerStyles} />
                ) : null,
              }}
            />
          </Grid>

          {/* Date Filter Dropdown */}
          <Grid item>
            <Tooltip title={`${startDate} to ${endDate}`} placement="top" arrow>
              <Autocomplete
                disablePortal
                disableClearable
                size="small"
                sx={{ width: 200 }}
                id="combo-box-demo"
                value={filter}
                options={["Today", "Weekly", "Monthly", "Yearly", "DateRange"]}
                getOptionLabel={(option) => option}
                onChange={(event, value) => handleFilterSelect(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Date Filter" />
                )}
              />
            </Tooltip>
          </Grid>

          {/* Export to Excel Button */}
          <Grid item>
            <Button
              variant="contained"
              sx={{
                backgroundColor: tableData.length === 0 ? "#b0b0b0" : "#000000",
                color: "#fff",
                "&:hover": {
                  backgroundColor:
                    tableData.length === 0 ? "#b0b0b0" : "#5051ba",
                },
              }}
              onClick={handleOpenExportDialog}
              disabled={tableData.length === 0}
            >
              Export Excel
            </Button>
          </Grid>
        </Box>
      </Box>

      {/* Loading Indicator */}
      <CustomLoader open={isLoading} />

      {/* Data Table */}
      <TableWrapper>
        <TableContainer component={Paper} style={{ maxHeight: "80vh" }}>
          <Table stickyHeader>
            <TableHead>
              <StyledTableRow>
                {[
                  "Employee ID",
                  "Sales Group",
                  "Retailer Name",
                  "Payer Name",
                  "Name",
                  "Payer ID",
                  "Type",
                  "Sub Type",
                  "Quantity",
                ].map((header) => (
                  <StyledTableCell
                    key={header} // Unique key for each column header
                    isHeader
                    sx={headerCellStyles} // Apply consistent styles
                  >
                    {header}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {tableData.length > 0 ? (
                tableData.map((entry, index) =>
                  entry.data
                    .filter((item) => Number(item.value) !== 0) // Filter out items where value is 0
                    .map((item, subIndex) => (
                      <StyledTableRow key={`${index}-${subIndex}`}>
                        <TableCell align="center">
                          <Tooltip
                            title={entry.emp_id || "-"}
                            placement="top"
                            arrow
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {truncateText(entry.emp_id || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={entry.salesGroup || "-"}
                            placement="top"
                            arrow
                          >
                            <span>
                              {truncateText(entry.salesGroup || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={entry.Retailer_Name || "-"}
                            placement="top"
                            arrow
                          >
                            <span>
                              {truncateText(entry.Retailer_Name || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={entry.Payer_Name || "-"}
                            placement="top"
                            arrow
                          >
                            <span>
                              {truncateText(entry.Payer_Name || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={entry.name || "-"}
                            placement="top"
                            arrow
                          >
                            <span>{truncateText(entry.name || "-", 15)}</span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={entry.payer_id || "-"}
                            placement="top"
                            arrow
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {truncateText(entry.payer_id || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={item.category || "-"}
                            placement="top"
                            arrow
                          >
                            <span>{item.category || "-"}</span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={item.itemName || "-"}
                            placement="top"
                            arrow
                          >
                            <span>
                              {truncateText(item.itemName || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip
                            title={item.value || "-"}
                            placement="top"
                            arrow
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {truncateText(item.value || "-", 15)}
                            </span>
                          </Tooltip>
                        </TableCell>
                      </StyledTableRow>
                    ))
                )
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    colSpan={7}
                    align="center"
                    sx={{ color: "#888" }}
                  >
                    No data is available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      </TableWrapper>

      {/* Image Viewer Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{ background: "#5051ba", color: "white", lineHeight: "0.6" }}
        >
          View Images
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon sx={{ color: "#ff0000fb" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Stack direction="column" spacing={2}>
            {selectedImages.map((image, index) => (
              <StyledImage key={index} src={image} alt={`Image ${index}`} />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Export Dialog */}
      <Dialog
        open={exportDialogOpen}
        onClose={handleCloseExportDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            color: "#556b2f",
            fontWeight: "bold",
            fontSize: "1.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Export to Excel
          <IconButton
            aria-label="close"
            onClick={handleCloseExportDialog}
            sx={{
              color: "#556b2f",
              "&:hover": {
                color: "red", // Change to your desired hover color
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ padding: "20px" }}>
          <Typography variant="body1" gutterBottom>
            Click "Export Now" to download the data as an Excel file.
          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#5051ba",
              color: "#fff",
              mt: 2,
              padding: "7px 12px",
              fontSize: "12px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "black", // Change this to your desired hover color
              },
            }}
            onClick={handleExportExcel}
          >
            Export Now
          </Button>
        </DialogContent>
      </Dialog>

      {/* Date Range Picker */}
      <CustomDialog
        open={openDateDialog}
        onClose={() => {
          setOpenDateDialog(false);
          setFilter();
        }}
        title="Select Date Range"
        fullscreen={false}
        maxWidth="sm"
      >
        {/* Start Date Field */}
        {renderDateField("Start Date", customDateRange.start_date, (e) =>
          setCustomDateRange({
            ...customDateRange,
            start_date: e.target.value,
          })
        )}

        {/* End Date Field */}
        {renderDateField("End Date", customDateRange.end_date, (e) =>
          setCustomDateRange({
            ...customDateRange,
            end_date: e.target.value,
          })
        )}

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setOpenDateDialog(false);
              setFilter();
            }} // Cancel button
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setStartDate(customDateRange.start_date);
              setEndDate(customDateRange.end_date);
              setCurrentPage(0);
              setOpenDateDialog(false); // Close dialog after submitting
              setFilter(
                `${customDateRange.start_date} to ${customDateRange.end_date}`
              );
            }}
            sx={{
              backgroundColor: "#5052ba",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#4042a3",
              },
              ml: 2,
            }}
          >
            Submit
          </Button>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default Compify;
