import axios from "axios";
import Cookies from "js-cookie"; // Import js-cookie

// Function to get access token from localStorage
const getAccessToken = () => localStorage.getItem("accessToken");

// Function to refresh access token using HTTP-only cookie
const refreshToken = async () => {
  try {
    console.log("Refreshing token..."); // Debugging log

    const response = await axios.post(
      "http://143.244.136.36:8084/api/refresh",
      {}, // No body needed, refresh token is in HTTP-only cookies
      { withCredentials: true } // Ensure cookies are sent and received
    );

    if (response.data.access_token) {
      localStorage.setItem("accessToken", response.data.access_token);
      console.log("New access token received:", response.data.access_token); // Debugging log
      return response.data.access_token;
    } else {
      console.error("Refresh token response did not contain access_token");
      throw new Error("No new access token received.");
    }
  } catch (error) {
    console.error("Refresh token failed:", error.response?.data || error);
    localStorage.clear();
    window.location.href = "/login"; // Redirect to login if refresh fails
    throw error;
  }
};

// Function to create an Axios instance with interceptors
const createAxiosInstance = (baseURL, useAuth = true) => {
  const instance = axios.create({
    baseURL,
    headers: { "Content-Type": "application/json" },
    withCredentials: useAuth, // Ensure cookies are included in requests
  });

  if (useAuth) {
    // Attach access token to every request
    instance.interceptors.request.use(
      (config) => {
        const token = getAccessToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Handle expired token (403 Forbidden) and refresh it
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // Log response errors for debugging
        console.error(
          "API Error:",
          error.response?.status,
          error.response?.data
        );

        // If access token expired (403) and request is not retried yet
        if (
          error.response?.status === 401 && // 🔹 Check for 403 (Forbidden)
          error.response?.data?.msg === "Missing cookie" &&
          !originalRequest._retry // Prevent infinite loop
        ) {
          originalRequest._retry = true; // Mark request as retried

          try {
            const newToken = await refreshToken();
            if (newToken) {
              // Update token for future requests
              instance.defaults.headers.Authorization = `Bearer ${newToken}`;
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              console.log(
                "Retrying request with new token:",
                originalRequest.url
              );
              return instance(originalRequest); // Retry the failed request
            }
          } catch (refreshError) {
            console.error("Token refresh error:", refreshError);
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  return instance;
};

// Axios instance with JWT authentication
// const axiosInstance1 = createAxiosInstance("http://192.168.0.26:8080", true);
const axiosInstance1 = createAxiosInstance("http://143.244.136.36:8084", true);

// Axios instances WITHOUT JWT authentication
const axiosInstance2 = createAxiosInstance(
  "http://prod.sapattea.com:5001",
  false
);
const axiosInstance3 = createAxiosInstance("http://143.244.133.43:5000", false);
const axiosInstance4 = createAxiosInstance("http://64.227.130.37:8082", false);

export { axiosInstance1, axiosInstance2, axiosInstance3, axiosInstance4 };
