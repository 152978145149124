import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeSkuViewDialog } from "../../feature/dialog/dialogSlice.js";
import CustomDialog from "../CustomDialog/CustomDialog.js";
import {
  getRequestInstanceOne,
  postRequest,
} from "../../apiServices/apiCall.js";
import API_ENDPOINTS from "../../apiServices/apiEndpoints.js";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Box,
  styled,
  Grid,
} from "@mui/material";

const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  height: "350px",
  "& .MuiTableBody-root": {
    overflowY: "auto",
    height: "auto",
    minHeight: "calc(6 * 56px)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
  padding: "8px 12px",
}));

const StyledTableCell = styled(TableCell)(({ theme, isHeader }) => ({
  backgroundColor: isHeader ? "#d3d4ee" : "transparent",
  color: theme.palette.common.black,
  padding: "12px",
  textAlign: isHeader ? "center" : "left",
  position: "sticky",
  top: 0,
  zIndex: 1,
  fontWeight: 600,
  fontFamily: "Arial, sans-serif",
  fontSize: "14px", // Default font size for desktop
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px", // Adjust font size for smaller screens
  },
}));

const DiscountRow = ({ label, value, color }) => (
  <StyledTableRow
    style={{ backgroundColor: label !== "FREE PKTS" ? "#65977b7a" : "#f5f5f5" }}
  >
    <CustomTableCell colSpan={2} style={{ fontWeight: "bold", color }}>
      {label}
    </CustomTableCell>
    <CustomTableCell style={{ fontWeight: "bold", color }}>:</CustomTableCell>
    <CustomTableCell />
    <CustomTableCell />
    <CustomTableCell
      style={{
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        color: "#5d7172",
      }}
    >
      <span style={{ marginRight: "8px" }}>{value || 0}</span>
    </CustomTableCell>
    <CustomTableCell />
    <CustomTableCell />
  </StyledTableRow>
);

const CustomTableCell = ({ children, align = "center", style, colSpan }) => (
  <TableCell
    align={align}
    sx={{ padding: "2px 2px" }}
    style={style}
    colSpan={colSpan}
  >
    {children}
  </TableCell>
);

const TableWrapper = styled(Box)({
  width: "100%",
});

const SkuViewDialog = () => {
  const dispatch = useDispatch();
  const { openViewDialog, orderId, start_date, end_date } = useSelector(
    (state) => state.dialog
  );
  const [viewData, setViewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discount_CD, setDiscount_CD] = useState(0);
  const [discount_free_pkts, setDiscount_free_pkts] = useState([]);
  const [viewData_PDF, setViewData_PDF] = useState([]);

  // API call to fetch order details
  const getOrderListViewItem1 = async (orderid) => {
    if (!orderid) {
      console.log("No Order ID provided, skipping API call.");
      return;
    }

    setIsLoading(true);
    try {
      const url = API_ENDPOINTS.Api_all_order_view;
      const params = {
        orderId: orderid,
        start_date,
        end_date,
      };
      const result = await getRequestInstanceOne(url, params);
      const allOrderViewData = result.data;

      if (allOrderViewData && allOrderViewData.length > 0) {
        setViewData(allOrderViewData);

        // Extract first order's discount details
        const orderData = allOrderViewData[0];
        setDiscount(orderData.discount || 0);
        setDiscount_CD(orderData.discount_cd || 0);

        const freePktsSku = orderData.free_pkts_sku || [];

        const aggregatedFreePkts = freePktsSku.map((item) => ({
          itemId: item.itemId,
          sku_qty: parseInt(item.sku_qty, 10), // Ensure it's a number
        }));

        setDiscount_free_pkts(aggregatedFreePkts);
      } else {
        setViewData([]);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
      setViewData([]);
    } finally {
      setIsLoading(false);
      fetchInvoiceGenerator(orderId);
    }
  };

  // Post api for PDF
  const fetchInvoiceGenerator = async (orderId) => {
    try {
      // Define API endpoint
      const endpoint = API_ENDPOINTS.Invoice_generator;
      const body = { orderId };
      // Use reusable postRequest function
      const result = await postRequest(endpoint, body);

      // Fetch all orders after invoice generation
      fetchAllOrderView(orderId);

      return result;
    } catch (error) {
      console.error("Error fetching invoice:", error);
    }
  };

  // Get api for PDF
  const fetchAllOrderView = async (orderid) => {
    const params = new URLSearchParams({
      orderId: orderid,
      invoice_number: orderid,
    });

    const url = API_ENDPOINTS.Api_all_order_view;

    try {
      const response = await getRequestInstanceOne(url, params);

      if (!response.ok) {
        console.warn(
          `Warning: API responded with status ${response.status} ${response.statusText}`
        );
      }

      const data = response;

      // Handle your data (e.g., set it in state)
      setViewData_PDF(data); // Uncomment if you have a state setter
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  let totalQty = 0;
  let totalAmount = 0;
  // let totalTPR = 0;
  let totalTax = 0;
  let totalFreePkts = 0;
  let totalCD = 0;
  let totalPrice = 0;
  let totalrateIncTax = 0;

  // Calculate totals
  if (viewData?.length > 0) {
    viewData.forEach((view) => {
      view.itemid.forEach((_, idx) => {
        const qty = parseFloat(view?.quantity[idx]) || 0;
        const price = parseFloat(view?.price[idx]) || 0;
        const total = qty * price;
        // const tpr = parseFloat(view?.discount[idx]) || 0;
        const tax = parseFloat(view?.tax[idx]) || 0;
        const freePkts = parseFloat(view?.free[idx]) || 0;
        const cd = parseFloat(view?.cash_disc[idx]) || 0;

        totalQty += qty;
        totalAmount += total;
        // totalTPR += tpr;
        totalTax += tax;
        totalFreePkts += freePkts;
        totalCD += cd;
        totalPrice += price;
        totalrateIncTax += price * (1 + tax / 100);
      });
    });
  }

  const calculateDiscountedAmount = () => {
    if (!(discount || discount_CD)) return totalAmount.toFixed(2);
    return Number(
      Number(totalAmount.toFixed(2) || 0) -
        Number(discount || 0) +
        Number(-discount_CD || 0)
    ).toFixed(2);
  };

  // Fetch order details when the dialog opens
  useEffect(() => {
    if (openViewDialog && orderId) {
      getOrderListViewItem1(orderId);
    }
  }, [openViewDialog, orderId, start_date, end_date]); // Run when dialog opens or orderId changes

  return (
    <CustomDialog
      open={openViewDialog}
      onClose={() => dispatch(closeSkuViewDialog())}
      title={`Order ID: ${orderId}`}
      PDFData={`PDF`}
      fullscreen={false}
      maxWidth="md"
      MyBgColor="#727297"
      ViewDATA={viewData_PDF}
      Discounted_Amount={discount_CD}
      free_pkts_sku={discount_free_pkts}
      company_scheme_disc={discount}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: { xs: "95%", sm: "80%", md: "md" },
        },
      }}
    >
      {isLoading ? (
        <p>Loading order details...</p>
      ) : viewData.length > 0 && orderId != null ? (
        <>
          {viewData.map((val, index) => (
            <Box key={index} sx={{ flexGrow: 1, overflowX: "auto", mb: 2 }}>
              <Grid
                container
                spacing={{ xs: 1, sm: 2 }}
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                      color: "maroon",
                    }}
                  >
                    OrderId:{" "}
                    <small style={{ color: "darkgreen" }}>{val?._id}</small>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "0.8rem", sm: "1rem" },
                      color: "maroon",
                    }}
                  >
                    Date:{" "}
                    <small style={{ color: "darkgreen" }}>
                      {val?.datetime}
                    </small>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box sx={{ overflowX: "auto", mt: 2 }}>
            <TableWrapper>
              <TableContainerWrapper component={Paper} sx={{ width: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {[
                        "SKU",
                        "MRP",
                        "Rate",
                        "Rate (Incl. of Tax)",
                        "Qty",
                        "Total",
                        "Tax",
                        "CD",
                      ].map((header) => (
                        <StyledTableCell
                          key={header}
                          isHeader
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                            color: "#835454",
                            fontSize: {
                              xs: "0.7rem",
                              sm: "0.8rem",
                              md: "0.9rem",
                            },
                            padding: "2px 2px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {header}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewData.length > 0
                      ? viewData.map((view, index) =>
                          view.itemid.map((_, idx) => {
                            const qty =
                              parseFloat(view?.quantity[idx])?.toFixed(2) || 0;
                            const price =
                              parseFloat(view?.price[idx])?.toFixed(2) || 0;
                            const total = (qty * price).toFixed(2);
                            // const tpr =
                            //   parseFloat(view?.discount[idx])?.toFixed(2) || 0;
                            const tax = parseFloat(view?.tax[idx]) || 0;
                            const cd =
                              parseFloat(view?.cash_disc[idx])?.toFixed(2) || 0;
                            const rateIncTax = (
                              price *
                              (1 + tax / 100)
                            ).toFixed(2);

                            return (
                              <StyledTableRow key={`${index}-${idx}`}>
                                {[
                                  view?.itemid[idx],
                                  "--",
                                  price,
                                  rateIncTax,
                                  qty,
                                  total,
                                  tax + "%",
                                  cd,
                                ].map((field, cellIndex) => (
                                  <TableCell
                                    key={cellIndex}
                                    align="center"
                                    sx={{ padding: "2px 2px" }}
                                  >
                                    {field || "--"}
                                  </TableCell>
                                ))}
                              </StyledTableRow>
                            );
                          })
                        )
                      : null}

                    {/* Summary Row */}
                    <StyledTableRow
                      sx={{ whiteSpace: "nowrap" }}
                      style={{ backgroundColor: "#e0e0e0" }}
                    >
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        Total
                      </CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        ---
                      </CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        {totalPrice.toFixed(2)}
                      </CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        {totalrateIncTax.toFixed(2)}
                      </CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        {totalQty.toFixed(2)}
                      </CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        {totalAmount.toFixed(2)}
                      </CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      ></CustomTableCell>
                      <CustomTableCell
                        style={{ fontWeight: "bold", color: "#936843" }}
                      >
                        {totalCD.toFixed(2)}
                      </CustomTableCell>
                    </StyledTableRow>

                    <DiscountRow
                      label="FREE PKTS"
                      value={
                        discount_free_pkts.reduce(
                          (sum, item) =>
                            sum + (parseInt(item.sku_qty, 10) || 0),
                          0
                        ) || 0
                      }
                      color="#4949B3"
                    />

                    <DiscountRow
                      label="CS DISCOUNT"
                      value={discount}
                      color="#4949B3"
                    />

                    <DiscountRow
                      label="CD"
                      value={discount_CD}
                      color="#4949B3"
                    />

                    <DiscountRow
                      label="TOTAL AFTER DISCOUNT"
                      value={calculateDiscountedAmount()}
                      color="#4949B3"
                    />
                  </TableBody>
                </Table>
              </TableContainerWrapper>
            </TableWrapper>
          </Box>
        </>
      ) : (
        <p>No data available for this order.</p>
      )}
    </CustomDialog>
  );
};

export default SkuViewDialog;
